<template lang="es">
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center ">
    
    <div class="w-full  flex flex-wrap h-full  h-full bg-white">
        
        <div class="fixed  w-full bg-gray-200 z-20 flex flex-wrap  h-12 justify-start pl-2 content-center">
            <!--<div class=" w-28 flex flex-wrap h-10">

                <el-date-picker class="    rounded h-full" v-model="month" type="month" lang="es" size="large" :clearable="false" value-format="YYYY-MM" placeholder="Mes" @change="recargar" />

            </div>-->
            <div    class="  flex flex-wrap h-10 text-xs font-extrabold content-center justify-center bg-white px-4 rounded">
                <!---
                                <el-date-picker class="    rounded h-full" v-model="month" type="month" lang="es" size="large" :clearable="false" value-format="YYYY-MM" placeholder="Mes" @change="recargar" />
                            -->
                        
                            <div >{{getMesAno(datesel)}}</div>
                            </div>
            <input v-model="cliente_s" placeholder="Nomb cliente" class="px-1 h-10 ml-2 rounded ng-white">
            <button class="px-2 py-1 h-10 bg-blue-200 text-black  ml-1 rounded" @click="getData()"><i class="las la-search"></i></button>
            <button v-if="cliente_s!=''" class="px-2 py-1 h-10 bg-red-200 text-black  ml-1 rounded" @click="cliente_s='';getData()"><i class="las la-times"></i></button>

            <!--<button class="px-3 py-1 h-12  bg-green-600 text-black  mx-2 ml-5 rounded" @click="activeView = 'day';keycalendar++">Dia</button>-->
            <!--<button class="px-3 py-1 h-12 bg-green-600 text-black mx-2 ml-5 rounded" @click="activeView = 'week';keycalendar++">Semana</button>-->
            <!--<button class="px-2 py-1 h-10 bg-yellow-400 text-black ml-1  rounded" @click="activeView = 'month';keycalendar++">Mes</button>-->
            <button @click="nowCal()" class="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded">Hoy</button>
            <button @click="prevCal()" class="ml-2 bg-white hover:bg-blue-600 text-black font-semibold py-2 px-4 rounded"><i class="las la-long-arrow-alt-left"></i></button>

            <button @click="nextCal()" class="ml-2 bg-white hover:bg-blue-600 text-black font-semibold py-2 px-4 rounded"><i class="las la-long-arrow-alt-right"></i></button>
           
            <button class="px-2 py-1 h-10 bg-blue-200 text-black  ml-1 rounded" @click="viewInfo=true"><i class="las la-info-circle"></i></button>

        </div>
        <el-dialog v-model="viewInfo" title="" :width="$isMobile()?'90%':'40%'" :before-close="handleClose">
            <div class="w-full  h-full overflow-x-hidden  transition ease-in-out duration-700" id="notification">
                <info_calendario></info_calendario>
            </div>

        </el-dialog>
        <el-dialog v-model="citasTable" title="" :width="$isMobile()?'90%':'80%'" :before-close="closeCitas">
            <div class="w-full  h-full overflow-x-hidden  transition ease-in-out duration-700" id="notification">
                <div class="w-full flex flex-wrap justify-center content-start  overflow-y-scroll px-2 py-2 bg-gray-100 h-80">
                    <div v-for="(row,index) in datos" v-bind:key="index" class='break-inside relative overflow-hidden flex flex-col justify-between space-y-1 text-sm rounded-xl w-full px-4 py-2 mb-2 bg-gray-200 text-black dark:bg-slate-800 dark:text-gray-200'>
                        <div class='flex items-center justify-between font-medium'>
                            <div class='uppercase text-xs text-green-500'>{{row.fecha_i}}</div>
                            <div class='text-xs text-slate-500' v-html="row.cliente"></div>
                            <div class='text-xs px-2 rounded-full ' v-html="row.cotizacion"></div>

                        </div>
                        <div class='flex flex-row items-center space-x-1'>

                            <div class='text-base font-medium' v-html="row.direccion"></div>
                        </div>
                        <div v-html="row.observaciones"></div>
                        <div class='flex  justify-between items-center'>
                            <div class="flex flex-wrap" v-html="row.iconos">

                            </div>

                            <a :href="'/app/cita?cid='+row.id+'&m='+getMes(row.fecha_i)+'&a='+getAno(row.fecha_i)" class='flex items-center justify-center text-xs font-medium rounded-full px-4 py-1 space-x-1 border-2 border-black bg-green-500 hover:bg-green-700 hover:text-black text-black dark:bg-green-500 dark:text-black dark:border-black dark:hover:bg-green-700 dark:hover:text-black'>
                                <span class="font-extrabold">Ver Cita</span>
                                <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'>
                                    <path d='M5 12h13M12 5l7 7-7 7' />
                                </svg>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </el-dialog>

        <calendarVc  :disabledviews_option="['years', 'year']" :time_option="false" :dataempleados="datos" :hora="timeline" :tipo_cita="'instalacion'" v-bind:key="keycalendar" :showid="showidcal" :activeView="activeView" :dia="datesel" />

    </div>

</section>
</template>

    
    
<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import categorias from "@/Services/categorias";
import citas from "@/Services/citas";
import cotizaciones from "@/Services/cotizaciones";
import cotizaciones_f from "@/functions/cotizaciones";
import productos from "@/Services/productos";
import parametros from "@/Services/parametros";
import generales from "@/functions/generales";
import calendarVc from "@/components/calndarVc";
import info_calendario from "@/components/info_calendario";

import calendario from "@/functions/calendario";
import usaurios from "@/Services/user";
import {
    conditionalExpression
} from "@babel/types";
import {
    MonthPickerInput
} from 'vue-month-picker'
usaurios
export default {
    components: {
        calendarVc,
        MonthPickerInput,
        info_calendario
    },
    mixins: [calendario, citas, categorias, generales, productos, parametros, usaurios, cotizaciones, cotizaciones_f],
    data() {
        const user = useUserStore()

        return {
            cid: 0,
            citasTable: false,
            open: false,
            filtro: 0,
            user: user.getAll(),
            datos: [],
            datesel:new Date(),
            instalador: 0,
            keycalendar: 1,
            showidcal: 0,
            viewInfo: false,
            activeView: 'week',
            timeline: null,
            tipo_view: 'crear',
            month:'',
            year:'',
           

        }

    },
    async mounted() {
        //emitter.emit("loading", true)
        //this.getData();
        // this.getDataCategorias();
        if (this.$route.query.hasOwnProperty('cid')) {
            this.cid = this.$route.query.cid;
        }

        if (this.$route.query.hasOwnProperty('a')) {
            this.a = this.$route.query.a;
            //await this.getDataCotizacion();
            // this.formatearCotizacionEditada();
        }
        // emitter.emit("loading", false)

    },
    created() {
      
      this.nowCal();

        emitter.on('getdataCitas', (data) => {
            
            this.getData();
        });
    },
    methods: {
        nowCal(){
            this.datesel = new Date();
            var nextDay = new Date();
          
            var month = (nextDay.getMonth() + 1).toString().padStart(2, '0');
            var year = nextDay.getFullYear().toString();
            this.month=month;
            this.year=year;
            this.keycalendar++;
            this.viewCal = true;
            this.getData();
        },
        prevCal(){
            this.viewCal = false;
            var currentDate = new Date(this.datesel);
            var nextDay = new Date(currentDate);
            nextDay.setDate(currentDate.getDate() - 7);
            var month = (nextDay.getMonth() + 1).toString().padStart(2, '0');
            var year = nextDay.getFullYear().toString();
            this.month=month;
            this.year=year;
            this.datesel = new Date(nextDay);
            this.keycalendar++;
            this.viewCal = true;
            this.getData();
        },
        nextCal() {
            //console.log(this.$refs.vuecal,'-reeeeff--')
            this.viewCal = false;
            console.log(this.datesel, '----'); // Apr 30 2000

            // Convert this.datesel to a Date object
            var currentDate = new Date(this.datesel);

            // Add 7 days to the current date
            var nextDay = new Date(currentDate);
            nextDay.setDate(currentDate.getDate() + 7);
            var month = (nextDay.getMonth() + 1).toString().padStart(2, '0');
            var year = nextDay.getFullYear().toString();
           
            this.month=month;
            this.year=year;

            console.log(nextDay); // May 07 2000

            // Update this.datesel with the new date
            this.datesel = new Date(nextDay);
            this.keycalendar++;
            this.viewCal = true;
            this.getData();
        },
        recargar($ev) {
            console.log($ev)
            this.month = $ev;
            this.getData();
        },

        closeCitas() {
            this.cliente_s = '';
            this.citasTable = false;
            this.getData()
        },
        limpiar() {

            this.id = 0;

            this.tipo_view = 'crear'

        },

        async getData() {
            //alert('aa')
            var today = new Date();
           
            var time =
                today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            this.timeline = time;
            this.showM = false;
            this.actionModal = null;

            this.datos = [];
            emitter.emit("loading", true)
            let dat = {
                tipo: 'instalacion',
                cliente: this.cliente_s,
                mes: this.month,
                ano:this.year,
            }
           
            await citas.GET(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    const tiposIncluidos = ['cita', 'anticipo', 'supervicion_instalacion',  'revision', 'medidas', 'pendiente','liquidacion','cotizacion'];
                
                    response.forEach(cita => {

                        /*if (cita.tipo == 'cita' || cita.tipo == 'pendiente') {
                            return
                        }*/
                        if (tiposIncluidos.includes(cita.tipo )) {
                            return
                        }

                        let saldo_pendiente = ` <p class="flex flex-wrap w-full px-1 pt-3 text-left text-black text-xs">SALDO PENDIENTE :
                        ${ this.formatPrice( this.calcularPendiente(cita.cotizaciones) )}</p> `;

                        let direccion = this.verfiNull(cita.calle) == "" ?
                            "" :
                            `<p class="flex flex-wrap w-full px-1 pt-3 text-left text-black text-xs">DIRECCIÓN : <a :href="'https://www.google.com/maps/search/?api=1&query='` + 'calle ' + this.verfiNull(cita.calle) + ' ' + this.verfiNull(cita.numero) + ' ' + this.verfiNull(cita.codigo_postal) + ' , ' + this.verfiNull(cita.colonia) + ' , ' + this.verfiNull(cita.municipio) + `" target="_blank" class="text-green-500">
                      ` + 'calle ' + this.verfiNull(cita.calle) + ' ' + this.verfiNull(cita.numero) + ' ' + this.verfiNull(cita.codigo_postal) + ' , ' + this.verfiNull(cita.colonia) + ' , ' + this.verfiNull(cita.municipio) + `</a></p>`

                        let usuario =
                            this.verfiNull(cita.users_id) == "" ?
                            "" :
                            `<p class="flex flex-wrap w-full px-1 text-left text-black text-xs">VENDEDOR :<strong class="text-red-500 text-xs"> ${cita.usuario.name}</strong></p>`

                        let cliente =
                            this.verfiNull(cita.clientes_id) == "" ?
                            "" :
                            `<p class="flex flex-wrap w-full px-1 text-left text-black text-xs">CLIENTE : <strong class="text-red-500 text-xs"> ${cita.clientes.name}  ${cita.clientes.apellido_p!=null?cita.clientes.apellido_p:''} ${cita.clientes.apellido_m!=null?cita.clientes.apellido_m:''}</strong></p>`
                        let cotizacion =
                            this.verfiNull(cita.cotizaciones_id) == "" ?
                            "" :
                            `
                       <span class="flex rounded-xl text-center content-center justify-center mx-1  h-6 w-14 flex flex-wrap bg-yellow-500"><strong class="text-black">#${cita.cotizaciones_id}</strong></span> `
                        let iconos = `
                      <span class=" rounded-xl text-center content-center justify-center mx-1 h-6 w-6 flex flex-wrap ${this.getColorTipo(cita.tipo)}  text-xl text-white"><strong class="text-white">
                        <i class="las ${this.getIcono(cita.tipo)}"></i>
                        </strong></span> 

                   
                      ${
                        this.verfiNull(cita.observaciones) == ""
                          ? ""
                          : `
                       <span class=" rounded-xl text-center content-center justify-center mx-1 h-6 w-6 flex flex-wrap bg-blue-500  text-xl text-white"><strong class="text-white">
                        <i class="las la-info"></i>
                        </strong></span> `
                      } 
    
                      ${
                        this.verfiNull(cita.status_instalacion) == ""
                          ? ""
                          : `
                       <span class=" rounded-xl text-center content-center justify-center mx-1 h-6 w-6 flex flex-wrap bg-gray-100 text-white"><strong class="text-white">
                        
                        ${
                        cita.status_instalacion == "aceptada" ?
                      
                        `<i class="text-green-500  text-2xl las la-thumbs-up"></i>`
                     
                          :   `<i class="text-red-500  text-2xl las la-thumbs-down"></i>`
                        }
    
                        </strong></span> `
                      } `
                        let observaciones =
                            this.verfiNull(cita.observaciones) == "" ?
                            "" :
                            `<p class="flex flex-wrap w-full px-1 pt-3 text-left text-black text-xs font-extrabold">OBSERVACIONES :<strong class="text-blue-500 text-xs"> ${cita.observaciones}</strong></p>`
                            let fecha_hora =
                            
                          
                            `<p class="flex flex-wrap w-full px-1 pt-3 text-left text-black text-xs font-extrabold"><strong class="text-blue-500 text-xs">${cita.fecha_inicio} </strong></p>`

                  
                        this.datos.push({
                            id: cita.id,
                            start: cita.fecha_inicio,
                            fecha_i: this.formatDateTimeLT(cita.fecha_inicio),
                            end: cita.fecha_fin,
                            title: cita.clientes.id,
                            content: `
                                
                                <div class="absolute content-start top-0 left-0 h-full border-4 border-${ this.getColor(cita.status,cita.status_instalacion)}-500 w-full py-1 px-1 flex flex-wrap">
             ${usuario}
             ${cliente}
             ${fecha_hora}
             ${cotizacion}
             ${iconos}
             ${saldo_pendiente}
              ${ direccion }
                     ${observaciones}

                    
                      </div>
                     `,
                            class: "card" + cita.status,
                            data: cita,
                            usuario: usuario,
                            cliente: cliente,
                            cotizacion: cotizacion,
                            iconos: iconos,
                            direccion: direccion,
                            observaciones: observaciones,

                            drag: true,

                            resizable: false,

                            split: 1,
                        });
                    });

                    // this.datos = response;
                    emitter.emit("loading", false)
                    this.keycalendar++;
                    if (this.cliente_s != '') {
                        this.citasTable = true;
                    }
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);
                    emitter.emit("loading", false)
                }

            });
        },

    }
}
</script>
