<template>
<div class="h-full flex flex-wrap w-full">

    <vue-cal ref="vuecal" locale="es" :time-from="9 * 60" :time-to="21 * 60" 
    :time-cell-height="parseInt(height/10.25)" :time="time_option" :selected-date="dia" :active-view="activeView" :disable-views="[disabledviews_option]" class=" demo  h-screen w-98 border-t border-gray-200 vuecal--full-height-delete" :events="dataempleados" :on-event-click="onEventClick" events-on-month-view="short" :time-step="60" @event-drop="eventoDrag" :editable-events="editable">

        <template v-slot:event="{ event,view  }">
            <!--<v-icon>{{ event.icon }}</v-icon>-->

            <div class="vuecal__event-title font-extrabold" />
            <!-- Or if your events are editable: -->

            <!-- <small class="vuecal__event-time"  >
             
              
                <strong>De</strong> <span>{{ ((event.start)) }} </span>
                <strong>a</strong> <span>{{ ((event.end))  }}</span>
            </small>-->
            <template v-if="time_option">
                <div class="vuecal__event-content h-20 overflow-hidden" v-if="view=='month'" v-html="event.content">

                </div>
                <div class="vuecal__event-content " v-else v-html="event.content">
    
                </div>
            </template>
            <template v-else>
              
                <div class="vuecal__event-content h-44 overflow-hidden" v-html="event.content">

                </div>
            </template>
           
        </template>

    </vue-cal>

    <el-dialog v-model="EdicionCreacionView" title="" :width="$isMobile()?'90%':'60%'" :fullscreen="false">
        <div class="w-full  h-full overflow-x-hidden  transition ease-in-out duration-700" id="notification">
            <div class="w-full bg-gray-50  overflow-y-scroll p-4 h-5/6 overflow-x-hidden">
                <div class="flex items-center justify-between ">
                    <p tabindex="0" class="focus:outline-none text-2xl font-semibold  text-gray-800">{{selectedEvent!=null?'Edición':'Creación'}} de cita</p>

                </div>

                <div class="border-b border-b-gray-200">
                    <ul class="-mb-px flex items-center gap-4 text-xs font-medium">
                        <li class="flex-1">
                            <a @click="taba='datos_cita'" href="#" class="select-all flex items-center justify-center gap-2 px-1 py-3 " :class="taba=='datos_cita'?'relative text-blue-700 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-blue-700 hover:text-blue-700':'text-gray-500 hover:text-blue-700'">
                                Datos cita</a>
                        </li>
                        <li class="flex-1">
                            <a @click="taba='ubicacion_detallada'" href="#" class=" select-all flex items-center justify-center gap-2 px-1 py-3 " :class="taba=='ubicacion_detallada'?'relative text-blue-700 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-blue-700 hover:text-blue-700':'text-gray-500 hover:text-blue-700'">
                                Ubicación </a>
                        </li>
                        <li class="flex-1">
                            <a @click="taba='ubicacion_lat_lng'" href="#" class="select-all flex items-center justify-center gap-2 px-1 py-3 " :class="taba=='ubicacion_lat_lng'?'relative text-blue-700 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-blue-700 hover:text-blue-700':'text-gray-500 hover:text-blue-700'">
                                Lat,Lng</a>
                        </li>
                        <li class="flex-1" v-if="tipo=='instalacion'">
                            <a @click="taba='productos'" href="#" class=" select-all flex items-center justify-center gap-2 px-1 py-3 " :class="taba=='productos'?'relative text-blue-700 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-blue-700 hover:text-blue-700':'text-gray-500 hover:text-blue-700'">
                                Productos</a>
                        </li>

                    </ul>
                </div>
                <div class="w-full flex flex-wrap content-start">
                    <div v-if="taba=='datos_cita'" class="w-full flex flex-wrap content-start">
                        <div class="w-full flex flex-wrap mt-5  overflow-y-scroll">

                            <div class=" flex flex-wrap w-full">
                                <div class="w-full px-2 flex flex-wrap  sm:w-1/2  justify-start">
                                    <div class="mb-5 w-full">
                                        <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                            Tipo
                                        </label>
                                        <el-select v-model="tipo" class="w-full" placeholder="Tipo de cita">
                                            <template v-for="item in tipos" :key="item.value">
                                                <el-option v-if="(item.visualiza).includes(tipo_cita)" :label="item.label" :value="item.value" />

                                            </template>

                                        </el-select>
                                    </div>
                                </div>
                                <div class="w-full px-2 flex flex-wrap  sm:w-1/2  justify-start">
                                    <div class="mb-5 w-full">
                                        <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                            Estatus
                                        </label>
                                        <el-select v-model="status" class="w-full" placeholder="Tipo de cita">
                                            <template v-for="item in ['agendada','confirmada','reagendada']" :key="item">
                                                <el-option  :label="item" :value="item" />

                                            </template>

                                        </el-select>
                                    </div>
                                </div>
                                <div class="w-full px-2  sm:w-1/2 flex flex-wrap">
                                    <div class="mb-5 w-full justify-start flex flex-wrap">
                                        <label for="lName" class="mb-3 block text-left text-base w-full font-medium text-[#07074D]">
                                            Fecha
                                        </label>
                                        <el-date-picker v-model="fecha" class="w-full " type="date" placeholder="Fecha" :default-value="new Date()" />
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-wrap w-full">
                                <div class="w-full px-2 flex flex-wrap   sm:w-1/2 justify-start">
                                    <div class="mb-5 w-full flex flex-wrap">
                                        <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                            Hora inicio
                                        </label>
                                        <el-time-select v-model="startTime" :max-time="endTime" class=" w-full" placeholder="Hora inicio" start="08:30" step="00:15" end="18:30" />
                                    </div>
                                </div>
                                <div class="w-full px-2 flex flex-wrap sm:w-1/2">
                                    <div class="mb-5 w-full flex flex-wrap">
                                        <label for="lName" class="mb-3 block text-left text-base font-medium text-[#07074D]">
                                            Hora fin
                                        </label>
                                        <el-time-select v-model="endTime" :min-time="startTime" placeholder="Hora fin" start="08:30" class=" w-full" step="00:15" end="18:30" />
                                    </div>
                                </div>
                            </div>

                            <div class=" flex flex-wrap w-full">
                                <div class="w-full px-2 flex flex-wrap  sm:w-1/2  justify-start">
                                    <div class="mb-5 w-full">
                                        <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                            <span> Empleado cita: </span>
                                        </label>
                                        <SearchSelectUsuariocitas v-bind:key="usuario" @carga="buscarGetUsuarios" :rows="usuarios" :label="'Usuario'" :valor="usuario" @seleccionado="action_usuario" />

                                    </div>
                                </div>
                                <div class="w-full px-2 flex flex-wrap  sm:w-1/2  justify-start">
                                    <div class="mb-5 w-full">
                                        <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                            Cliente
                                        </label>
                                        <SearchSelectClientecitas v-bind:key="cliente" @carga="buscarGet" :rows="clientes" :label="'cliente'" :valor="cliente" @seleccionado="action_cliente" />

                                    </div>
                                </div>
                                <div class="w-full px-2  sm:w-1/2 flex flex-wrap justify-start">
                                    <div class="mb-5 w-full">
                                        <label for="lName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                            Cotizacion
                                        </label>
                                        <SearchSelectCotizacion class="w-full" v-bind:key="cotizacion" @carga="buscarGetCotizaciones" :rows="cotizaciones" :label="'cotizacion'"  :valor="cotizacion" @seleccionado="action_cotizacion" />

                                    </div>
                                </div>
                                <div v-if="tipo != 'cita'&&tipo!='medidas'" class="w-full px-2 flex flex-wrap  sm:w-1/2  justify-start">
                                    <div class="mb-5 w-full">
                                        <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                            Instalador
                                        </label>
                                        <SearchSelectUsuariocitas v-bind:key="instalador" @carga="buscarGetInstaladores" :rows="instaladores" :label="'Instalador'" :valor="instalador" @seleccionado="action_instalador" />

                                    </div>
                                </div>
                            </div>

                            <div class=" flex flex-wrap w-full">
                                <div class="w-full px-2 flex flex-wrap  w-full  justify-start">
                                    <div class="mb-5 w-full">
                                        <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                                            Observaciones
                                        </label>
                                        <textarea v-model="observaciones" class="h-24 w-full border-2"></textarea>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div v-if="taba=='ubicacion_detallada'" class="w-full flex flex-wrap content-start">
                        <div class="flex flex-wrap w-full">
                            <div class="w-full px-2 flex flex-wrap   sm:w-1/3 justify-start">
                                <div class="mb-5 w-full flex flex-wrap">
                                    <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                        Municipio
                                    </label>

                                    <input v-model="municipio" class="rounded w-full py-2 px-2 text-center border text-black text-xs border-gray-200">
                                </div>
                            </div>
                            <div class="w-full px-2 flex flex-wrap sm:w-1/3">
                                <div class="mb-5 w-full flex flex-wrap">
                                    <label for="lName" class="mb-3 block text-left text-base font-medium text-[#07074D]">
                                        Codigo postal
                                    </label>

                                    <input v-model="codigo_postal" class="rounded w-full py-2 px-2 text-center border text-black text-xs border-gray-200">
                                </div>
                            </div>
                            <div class="w-full px-2 flex flex-wrap   sm:w-1/3 justify-start">
                                <div class="mb-5 w-full flex flex-wrap">
                                    <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                        Colonia
                                    </label>
                                    <input v-model="colonia" class="rounded w-full py-2 px-2 text-center border text-black text-xs border-gray-200">
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap w-full">

                            <div class="w-full px-2 flex flex-wrap sm:w-1/2">
                                <div class="mb-5 w-full flex flex-wrap">
                                    <label for="lName" class="mb-3 block text-left text-base font-medium text-[#07074D]">
                                        Calle
                                    </label>

                                    <input v-model="calle" class="rounded w-full py-2 px-2 text-center border text-black text-xs border-gray-200">
                                </div>
                            </div>
                            <div class="w-full px-2 flex flex-wrap   sm:w-1/2 justify-start">
                                <div class="mb-5 w-full flex flex-wrap">
                                    <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                        Numero
                                    </label>
                                    <input v-model="numero" class="rounded w-full py-2 px-2 text-center border text-black text-xs border-gray-200">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="taba=='ubicacion_lat_lng'" class="w-full flex flex-wrap content-start">
                        <div class="flex flex-wrap w-full">
                            <div class="w-full px-2 flex flex-wrap   sm:w-1/3 justify-start">
                                <div class="mb-5 w-full flex flex-wrap">
                                    <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                        lat
                                    </label>

                                    <input v-model="lat" class="rounded w-full py-2 px-2 text-center border text-black text-xs border-gray-200">
                                </div>
                            </div>
                            <div class="w-full px-2 flex flex-wrap   sm:w-1/3 justify-start">
                                <div class="mb-5 w-full flex flex-wrap">
                                    <label for="fName" class="mb-3 block text-base font-medium  text-left text-[#07074D]">
                                        lng
                                    </label>

                                    <input v-model="lng" class="rounded w-full py-2 px-2 text-center border text-black text-xs border-gray-200">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="taba=='productos'" class="w-full flex flex-wrap content-start">

                        <div v-if="cotizacion!=null&&cotizacion.hasOwnProperty('productos_cotizaciones')" class="w-full flex flex-wrap">
                            <!--creacion-->
                            <div class=" w-full overflow-x-scroll   border-black  content-start justify-start flex-wrap h-96">

                                <div class="w-auto flex flex-wrap justify-start content-start sticky top-0">

                                    <div class="w-auto flex flex-nowrap bg-blue-100">

                                        <div class="w-32 border text-xs border-black h-10 "><span class="align-middle">Listos para instalar</span></div>
                                        <div class="w-32 border text-xs border-black h-10"><span class="align-middle">Nombre</span></div>

                                        <div class="w-48 border text-xs border-black h-10"><span class="align-middle">Cantidad por instalar</span></div>
                                    </div>
                                </div>
                                <div class="w-auto  flex flex-wrap " v-for="( row,index) in cotizacion.productos_cotizaciones" v-bind:key="index">
                                    <div class="w-auto flex flex-nowrap bg-blue-100">

                                        <div class="w-32 border text-xs border-black h-10 "><span class="align-middle select-all">{{Number(row.produccion).toFixed(0)}}</span></div>
                                        <div class="w-32 border text-xs border-black h-10"><span class="align-middle select-all">{{row.productos.nombre}}</span></div>

                                        <div class="w-48 border text-xs border-black h-10">
                                            <div class=" font-extrabold text-green-500  h-10 w-48">
                                                <div class="w-full h-10  border-b border-r  border-black text-xs  text-blue-600 font-extrabold justify-center content-center flex flex-wrap">
                                                    <div class="w-full flex flex-wrap items-center  h-full">

                                                        <div @click="row.cantidad_inst>0?row.cantidad_inst=row.cantidad_inst-1:0;" class=" w-4/12 flex cursor-pointer hover:bg-red-500 flex-shrink-0 justify-center items-center  h-full text-red-500 bg-red-200  dark:text-blue-300 dark:bg-red-900">
                                                            <span class="text-black text-xs font-extrabold">-</span>
                                                        </div>
                                                        <div class="overflow-hidden w-4/12 flex flex-shrink-0 justify-center items-center  h-full text-blue-500   dark:text-blue-300 dark:bg-blue-900">
                                                            <!--<span class="text-black text-xs font-extrabold">{{Number(cantidad).toFixed(0)}}</span>-->
                                                            <input :max="row.produccion" :value="row.cantidad_inst" v-on:keyup="formatNumber($event)" class="  bg-white h-full w-full text-center text-xs  border-0 border-gray-200 placeholder-gray-300" type="text" name="card_number" placeholder="" />

                                                        </div>
                                                        <div @click="row.produccion>row.cantidad_inst?row.cantidad_inst=row.cantidad_inst+1:0;" class=" w-4/12 flex cursor-pointer hover:bg-green-500 flex-shrink-0 justify-center items-center  h-full text-blue-500 bg-green-200  dark:text-blue-300 dark:bg-blue-900">
                                                            <span class="text-black text-xs font-extrabold">+</span>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <!--- <number_controls :maximo="row.produccion" :cantidad="row.cantidad_inst" @mas="(k)=>{row.cantidad_inst++}" @min="(k)=>{row.cantidad_inst--}" @update:val="(val)=>{row.cantidad_inst=val}"></number_controls>
                                    -->
                                            <!--  @change="handleChange"-->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full flex flex-wrap content-start">

                    </div>

                </div>
                <btn_component :text="'Guardar'" :tipo="'success'" @click="guardarCotizacion()"></btn_component>

                <!--<button @click="guardarCotizacion()" type="button" class="px-4 mx-2 rounded py-2 bg-green-500 text-white font-extrabold">Guardar</button>-->

            </div>
        </div>

    </el-dialog>

    <div style="z-index:9999900" v-if="dataeliminar!=null" @click.self="dataeliminar=null" class="bg-black bg-opacity-50 px-72  h-screen w-full flex px-40 content-center justify-center  fixed  top-0 right-0 bottom-0 left-0">
        <div class=" bg-white w-full  self-center  flex-wrap px-5 py-14 rounded-md text-start  content-center justify-center items-center">
            <div class="text-base mb-4 text-center font-bold text-slate-500">¿Desea eliminar esta cita?
            </div>
            <div class="flex-wrap flex justify-between  w-full">

                <div class="w-2/6 px-2"><button class="w-full bg-blue-500 font-extrabold bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500" @click="dataeliminar=null">Cancelar</button>
                </div>
                <div class="w-2/6 px-2">
                    <button class="bg-red-500 px-4 font-extrabold  py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full  border-2 border-red-500" @click="$parent.Eliminar(dataeliminar)">Eliminar</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="showDialog" @click.self="showDialog=false" class="w-full h-full bg-black bg-opacity-20 z-40 fixed top-0 left-0 justify-center content-center flex flex-wrap">

        <div class="w-80  bg-white self-center justify-center flex-flex-wrap">
            <div class="w-full cursor-pointer bg-gray-100 bg-opacity-10 flex flex-wrap justify-end hover:bg-opacity-20 h-12  text-end" @click="showDialog=false;">
                <i class="text-3xl las la-window-close"></i>
            </div>
            <template v-if="selectedEvent.data.cotizaciones_id!=null ">
                <a v-if="selectedEvent.data.cotizaciones.idventa==null"  :href="'/app/cotizaciones?cid='+selectedEvent.data.cotizaciones.id" class="select-all mb-10 w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center   text-yellow-600 font-extrabold text-3xl">
                    <span style="font-weight:bold;">#Cotizacion</span>:
                    {{ selectedEvent.data.cotizaciones.id}}
    
                </a>
                <a v-else  :href="'/app/detalle/ventas?cid='+selectedEvent.data.cotizaciones.id" class=" select-all mb-10 w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center   text-yellow-600 font-extrabold text-3xl">
                    <span style="font-weight:bold;">#Pedido</span>:
                    {{ selectedEvent.data.cotizaciones.idventa}}
    
                </a>
            </template>
            <div class="mt-5 w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                <span> TIPO CITA: </span>

                <span class="text-gray-600 uppercase select-all ">{{selectedEvent.data.tipo}} </span>

            </div>
            <div class="mt-5 w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                <span> ESTATUS CITA: </span>

                <span class="text-gray-600 uppercase select-all ">{{selectedEvent.data.status}} </span>

            </div>
            <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                <span> EMPLEADO CITA: </span>

                <span class="select-all" v-if="selectedEvent.data.usuario!=null">{{selectedEvent.data.usuario.name}} </span>
                <span class="select-all" v-else>{{'SIN ASIGNAR'}}</span>

            </div>
            <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                <span> CLIENTE: </span>

                <span class="select-all ">{{selectedEvent.data.clientes.name}} {{selectedEvent.data.clientes.apellido_p}} {{selectedEvent.data.clientes.apellido_m}}/{{selectedEvent.data.clientes.phone}}  </span>

            </div>

            <div v-if="selectedEvent.data.instalador_id!=null" class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                <span> INSTALADOR: </span>

                <span class="select-all">{{selectedEvent.data.instalador.name}} </span>

            </div>

            <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                <span> FECHA: </span>

                <span class="select-all">{{formatDateEN(selectedEvent.data.fecha_inicio)}} {{parseTimeN(selectedEvent.data.fecha_inicio)}} </span>

            </div>
            <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                <span> OBSERVACIONES: </span>

                <span class="select-all">{{selectedEvent.data.observaciones}} </span>

            </div>
            <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-red-600 font-bold">
                <span> DIRECCION: </span>

                <a :href="'https://www.google.com/maps/search/?api=1&query='+(''+selectedEvent.data.calle+' '+selectedEvent.data.numero+' '+this.verfiNull(selectedEvent.data.codigo_postal)+' , '+selectedEvent.data.colonia+' , '+selectedEvent.data.municipio)" target="_blank" class="text-green-500 select-all">
                    {{this.verfiNull(selectedEvent.data.calle)+' '+this.verfiNull(selectedEvent.data.numero)+' '+this.verfiNull(selectedEvent.data.codigo_postal)+' , '+this.verfiNull(selectedEvent.data.colonia)+' , '+this.verfiNull(selectedEvent.data.municipio)}}</a>

            </div>

            <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  flex flex-wrap justify-between px-2 py-2 text-center text-sm text-blue-600 font-bold">
                <span> TELEFONO: </span>

                <span class="select-all">{{selectedEvent.data.clientes.phone}} </span>

            </div>

            <div class="w-full cursor-pointer border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center">
                <el-popconfirm confirm-button-text="Si" cancel-button-text="No" :icon="InfoFilled" icon-color="#626AEF" title="Esta deguro de eliminar esta cita?" @confirm="eliminar(selectedEvent.data.id)">
                    <template #reference>
                        <el-button type="danger">Eliminar</el-button>
                    </template>
                </el-popconfirm>

            </div>

            <div v-if="selectedEvent.data.status=='agendada'" class="w-full cursor-pointer border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center">
                <btn_component :text="'Confirmar'" :tipo="'success'" @click="cambiarStatus(selectedEvent.data.id,'confirmada')"></btn_component>
                <!--<el-button type="success" @click="cambiarStatus(selectedEvent.data.id,'realizada')">Realizada</el-button>
                <el-button type="warning" @click="cambiarStatus(selectedEvent.data.id,'reagendada')">Reagendada</el-button>-->
            </div>
            <div v-if="selectedEvent.data.status=='confirmada'" class="w-full cursor-pointer border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center">
                <btn_component :text="'Realizada'" :tipo="'success'" @click="cambiarStatus(selectedEvent.data.id,'realizada')"></btn_component>
                <btn_component :text="'Reagendada'" :tipo="'warning'" @click="cambiarStatus(selectedEvent.data.id,'reagendada')"></btn_component>
            </div>
            <div v-if="selectedEvent.data.status=='confirmada'" class="w-full cursor-pointer border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center">
                <btn_component :text="'No atendida'" :tipo="'info'" @click="cambiarStatus(selectedEvent.data.id,'noatendida')"></btn_component>
                <btn_component :text="'Cancelar cita'" :tipo="'danger'" @click="cambiarStatus(selectedEvent.data.id,'cancelada')"></btn_component>
                <!--<el-button type="info" @click="cambiarStatus(selectedEvent.data.id,'noatendida')">No atendida</el-button>
                <el-button type="danger" @click="cambiarStatus(selectedEvent.data.id,'cancelada')">Cancelar cita</el-button>-->
            </div>

            <div class="w-full cursor-pointer border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20 h-12 px-2 py-2 text-center" @click="setEditar(selectedEvent.data)">Editar</div>
        </div>
    </div>
    <button @click="this.limpiar();EdicionCreacionView=true" class="btn font-bold bg-blue-800 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed z-20  float-right m-r-xxs rounded-full fixed  bottom-5 right-5 ">
        <i class="las la-plus"></i>
    </button>
</div>
</template>

<script >
//@ts-nocheck

var body = document.body,
    html = document.documentElement;

var height = Math.max(body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight);
console.log(height, '----he------')

//@ts-ignore
import generales from '@/functions/generales'

import citas from "@/Services/citas";
import SearchSelectCotizacion from "@/components/SearchSelectCotizacion"
import SearchSelectClientecitas from "@/components/SearchSelectClientecitas"
import SearchSelectUsuariocitas from "@/components/SearchSelectUsuariocitas"

import cotizaciones from "@/Services/cotizaciones";

import usuarios from "@/Services/user";
//import palabras from "@/mixins/palabras";
import {
    useInfoStore
} from '../stores/info'
//@ts-ignore
import {
    emitter
} from "@/mitt.js";
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
export default {
    name: "calendarVc",
    components: {
        VueCal,
        SearchSelectCotizacion,
        SearchSelectClientecitas,
        SearchSelectUsuariocitas
    },

    mixins: [generales],
    props: {
        time_option:{
            type: Boolean,
            default: true
        },
        disabledviews_option:{
            type:Array,
            default:new Array
        },
        tipo_cita: {
            type: String,
            default: ''
        },
        msg: {
            type: String,
            default: ''
        },
        dataempleados: {
            type: Array

        },
        hora: {
            type: Date,
            default: new Date
        },
        showid: {
            type: Number,
            default: 0
        },
        dia: {
            type: Date,
            default: new Date
        },
        activeView: {
            type: String
        }

    },
    data() {
        return {
            EdicionCreacionView: false,
            selectedEvent: null,
            showDialog: false,
            dataeliminar: null,
            fecha: new Date(),
            endTime: '13:30',
            startTime: '07:00',
            height: height - 110,
            tipo: 'cita',
            tab: 'first',
            taba: 'datos_cita',
            fotos: '',
            lat: '',
            lng: '',
            buscar_c: '',
            buscar_cliente: '',
            buscar_instalador: '',
            instalador: null,
            instaladores: [],
            observaciones: '',
            buscar: '',
            pagina: 1,
            editable: {
                title: false,
                drag: true,
                resize: true
            },

            id: 0,
            limit: 12,
            usuario: null,
            usuarios: [],
            clientes: [],
            cotizaciones: [],
            cotizacion: null,
            produtos_instalados_citas: [],
            cliente: null,
            tipos: [{
                    value: 'cita',
                    label: 'Cita general',
                    visualiza: ['citas_generales']
                },
                {
                    value: 'anticipo',
                    label: 'Anticipo',
                    visualiza: ['citas_generales']
                },
                {
                    value: 'liquidacion',
                    label: 'Liquidación',
                    visualiza: ['citas_generales']
                },
                {
                    value: 'cotizacion',
                    label: 'Cotización',
                    visualiza: ['citas_generales']
                },
                {
                    value: 'supervicion_instalacion',
                    label: 'Supervicion instalación',
                    visualiza: ['citas_generales']
                },
                {
                    value: 'reparacion',
                    label: 'Reparación',
                    visualiza: ['instalacion']
                },
                {
                    value: 'revision',
                    label: 'Revision',
                    visualiza: ['citas_generales']
                },
                
                {
                    value: 'fecha_asignada',
                    label: 'Fecha asignada',
                    visualiza: ['instalacion']
                },
                {
                    value: 'pendiente',
                    label: 'Pendiente',
                    visualiza: ['citas_generales']
                },
                {
                    value: 'medidas',
                    label: 'Revision de Medidas',
                    visualiza: ['instalacion']
                },
                {
                    value: 'instalacion',
                    label: 'Instalación programada',
                    visualiza: ['instalacion']
                },
                {
                    value: 'mantenimiento',
                    label: 'Manteniemiento programado',
                    visualiza: ['instalacion']
                }
            ],
            municipio: '',
            codigo_postal: '',
            calle: '',
            status:'AGENDADA',
            colonia: '',
            numero: '',
            selectedDate: this.dia
        }
    },

    computed: {
        // Get the Monday of the real time current week.
        previousFirstDayOfWeek() {
            return new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 6) % 7))
        }
    },
    methods: {
        formatNumber(event) {

            var inputChar = String.fromCharCode(event.keyCode);
            var code = event.keyCode;
            var allowedKeys = [8];

            console.log()
            if (allowedKeys.indexOf(code) !== -1) {
                return;
            }
            event.target.value = event.target.value
                .replace(
                    /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
                );

        },
        async returnVal(val) {
            this.$root.$emit("loading", true);
            let data_c = {
                id: val.event.id,

                fecha_inicio: this.formatDateTime(val.event.start),
                fecha_fin: this.formatDateTime(val.event.end),
            };
            console.log(this.formatDateTime(val.event.end), '----fin-----')
            console.log(this.formatDateTime(val.event.start), '----inicio-----')

            await citas.EDITAR_FECHA(data_c).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.showDialog = false;
                    emitter.emit("getdataCitas", true)
                } else {

                    emitter.emit("alerta_show", response.error);

                }

            });

        },

        setEditar(data) {
            console.log(data, '....----')
            this.showDialog = false;
            this.EdicionCreacionView = true;
            this.cliente = data.clientes;
            this.usuario = data.usuario;
            this.status = data.status;
            this.instalador = data.instalador;
            this.produtos_instalados_citas = data.produtos_instalados_citas;
            if (data.cotizaciones_id != null) {
                console.log('----entro--', data.cotizaciones)
                this.cotizacion = data.cotizaciones;

                this.produtos_instalados_citas.forEach(element => {

                    this.cotizacion.productos_cotizaciones.forEach(producto => {
                        if (element.productos_cotizaciones_id == producto.id) {
                            producto.cantidad_inst = element.cantidad;
                        } else {
                            //producto.cantidad_inst = 0;
                        }

                    });

                });
            }

            this.startTime = this.getTime(data.fecha_inicio);
            this.endTime = this.getTime(data.fecha_fin);
            this.fecha = data.fecha;
            this.tipo = data.tipo;
            this.lat = data.lat;
            this.lng = data.lng;

            this.municipio = data.municipio;
            this.numero = data.numero;
            this.calle = data.calle;
            this.colonia = data.colonia;
            this.codigo_postal = data.codigo_postal;
            this.id = data.id;
            this.observaciones = data.observaciones;
            this.EdicionCreacionView = true;

        },
        async guardarCotizacion() {
            let dat = {

            }
            if ((this.tipo == 'instalacion' || this.tipo == 'mantenimiento' || this.tipo == 'medidas'|| this.tipo == 'medidas'|| this.tipo == 'revision'|| this.tipo == 'instalacion') && this.cotizacion == null) {
                emitter.emit("alerta_show", 'las citas de tipo instalacion, revicion de medidas o mantenimiento es obligatorio selecionar una cotización');
                return;
            } else {
                if (this.cotizacion == null) {

                } else {
                    dat.cotizacion = this.cotizacion.id
                    dat.productos = [];

                    this.cotizacion.productos_cotizaciones.forEach(element => {
                        if (element.cantidad_inst > 0) {
                            dat.productos.push({
                                producto_id: element.id,
                                cantidad: element.cantidad_inst
                            })
                        }

                    });

                }

            }
            if (this.status == null||this.status == '') {
                emitter.emit("alerta_show", 'Es necesario seleccionar un estatus');
                return;
            }
            if (this.cliente == null) {
                emitter.emit("alerta_show", 'Es necesario seleccionar un cliente');
                return;
            }
            if (this.usuario == null) {
                emitter.emit("alerta_show", 'Es necesario seleccionar un vendedor');
                return;
            }
            if (this.fecha == null || this.fecha == '') {
                emitter.emit("alerta_show", 'La fecha no puede estar vacia');
                return;
            }
            if (this.startTime == null || this.startTime == '') {
                emitter.emit("alerta_show", 'La hora de inicio no puede estar vacia');
                return;
            }
            if (this.endTime == null || this.endTime == '') {
                emitter.emit("alerta_show", 'La hora de finalizacion no puede estar vacia');
                return;
            }
//mantenimiento,instalacion,fecha_asignada,revision,reparacion,medidas,pendiente,supervicion_instalacion
            if ((this.instalador == null || this.instalador == '') && this.tipo != 'cita'&& this.tipo != 'anticipo'&& this.tipo != 'supervicion_instalacion'&& this.tipo != 'fecha_asignada'&& this.tipo != 'revision' && this.tipo != 'medidas'&& this.tipo != 'fecha_asignada'&& this.tipo != 'pendiente'&& this.tipo != 'liquidacion') {
                emitter.emit("alerta_show", 'El instalador es obligatorio no puede estar vacio');
                return;
            }
            if ((this.instalador != null && this.instalador != '')) {
                dat.instalador = this.instalador.id;

            }
            dat.id = this.id;
            dat.hora_inicio = this.formatTime(this.startTime);
            dat.hora_fin = this.formatTime(this.endTime);
            dat.fecha = this.formatDateEN(this.fecha);
            dat.observaciones = this.observaciones;
            dat.municipio = this.municipio;
            dat.lat = this.lat;
            dat.lng = this.lng;
            dat.colonia = this.colonia;
            dat.status = this.status;
            dat.numero = this.numero;
            dat.calle = this.calle;
            dat.codigo_postal = this.codigo_postal;
            dat.cliente = this.cliente.id;
            dat.usuario = this.usuario.id;

            dat.tipo = this.tipo;

            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        action_instalador(ev) {
            this.instalador = ev.value;

        },
        buscarGetInstaladores(busqueda) {
            this.buscar_instalador = busqueda;
            this.getDataInstaladores();

        },
        buscarGetCotizaciones(busqueda) {
            console.log('...----', busqueda)
            this.buscar_c = busqueda;
            this.getDataCotizaciones();

        },
        async getDataInstaladores() {

            let dat = {
                search: this.buscar_instalador,
                page: this.pagina,
                limit: this.limit,
                data: {}
            }

            await usuarios.GET_INSTALADORES(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.instaladores = response;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },
        async getDataCotizaciones() {

            let dat = {
                search: this.buscar_c,
                page: this.pagina,
                limit: this.limit,
                tipo: this.tipo_cita,
                ruta:this.ruta,
                data: {}
            }

            await cotizaciones.GET_VENTA_COTIZACION(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    if (this.$route.query.hasOwnProperty('idc')||this.$route.query.hasOwnProperty('idv')) {

                        response.data.forEach(element => {
                            if ((this.$route.query.hasOwnProperty('idc')&&element.id == this.$route.query.idc)||
                            this.$route.query.hasOwnProperty('idv')&&element.idventa == this.$route.query.idv) {
                                this.cotizacion = element;
                                this.cotizacion.productos_cotizaciones.forEach(element => {
                                    element.cantidad_inst = 0;

                                });
                                if (element.hasOwnProperty('usuario')) {
                                    this.usuario = element.usuario;
                                }
                                if (element.hasOwnProperty('clientes')) {
                                    this.cliente = element.clientes;
                                    this.observaciones = this.cliente.observaciones;
                                    this.municipio = this.cliente.municipio;
                                    this.colonia = this.cliente.colonia;
                                    this.numero = this.cliente.numero;
                                    this.calle = this.cliente.calle;
                                    this.codigo_postal = this.cliente.codigo_postal;
                                } else {
                                    this.cliente = null;
                                }

                            }
                        });

                        this.cotizaciones = response.data;
                        // this.cotizacion=
                    } else {
                        this.cotizaciones = response.data;
                    }

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },

        buscarGet(busqueda) {
            this.buscar = busqueda;

            this.getDataClientes();

        },
        buscarGetUsuarios(busqueda) {
            this.buscar_cliente = busqueda;
            this.getDataUsuarios();

        },
        async getDataUsuarios() {

            let dat = {
                search: this.buscar_cliente,
                page: this.pagina,
                limit: this.limit,
                data: {}
            }

            await usuarios.GET_USUARIOS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.usuarios = response;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },

        async getDataClientes() {

            let dat = {
                search: this.buscar,
                page: this.pagina,
                limit: this.limit,
                data: {}
            }

            await usuarios.GET_CLIENTES(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.clientes = response;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },
        action_usuario(ev) {
            this.usuario = ev.value;

        },
        limpiar() {
            this.cliente = null;

            this.observaciones = '';
            this.municipio = '';
            this.colonia = '';
            this.numero = '';
            this.lat = '';
            this.lng = '';
            this.calle = '';
            this.status = '';
            this.codigo_postal = '';
            this.cotizacion = null;
            this.fecha = new Date();
            this.endTime = '13:30';
            this.startTime = '09:30';
        },
        action_cliente(ev) {
            this.cliente = ev.value;
            console.log(this.cliente, '---cleiente--')
            this.observaciones = this.cliente.observaciones;
            this.municipio = this.cliente.municipio;
            this.colonia = this.cliente.colonia;
            this.numero = this.cliente.numero;
            this.calle = this.cliente.calle;
            this.codigo_postal = this.cliente.codigo_postal;

        },
        action_cotizacion(ev) {
            console.log(ev.value)
            if (ev.value.hasOwnProperty('clientes')) {
                this.cliente = ev.value.clientes

            } else {
                this.cliente = null;
            }
            if (ev.value.hasOwnProperty('usuario')) {
                this.usuario = ev.value.usuario
            }

            this.cotizacion = ev.value;

            this.cotizacion.productos_cotizaciones.forEach(element => {
                element.cantidad_inst = 0;

            });
            console.log(ev.value, '-----')

        },

        async cambiarStatus(id, status) {
            emitter.emit("loading", true)
            let dat = {
                id: id,
                status: status
            }
            await citas.EDITAR_STATUS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    // this.getData();
                    //this.limpiar();
                    this.showDialog = false;
                    emitter.emit("getdataCitas", true)
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;

                emitter.emit("loading", false)
            });
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await citas.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    // this.getData();
                    //this.limpiar();
                    this.EdicionCreacionView = false;
                    emitter.emit("getdataCitas", true)
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;

                emitter.emit("loading", false)
            });
        },

        async crear(dat) {
            emitter.emit("loading", true)

            await citas.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.EdicionCreacionView = false;
                    emitter.emit("getdataCitas", true)
                    // this.showM = false;
                    // this.limpiar();
                    emitter.emit("loading", false)
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);
                    emitter.emit("loading", false)
                }

            });
        },

        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await citas.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    emitter.emit("getdataCitas", true)
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },
        getTime(date) {
            let tiempo = date.split(' ')
            return tiempo[1];
        },
        formatTime(time) {
            let tiempo = time.split(':')
            return tiempo[0] + ":" + tiempo[1];
        },
        parseTimeN(date) {
            const event = new Date(date);

            return event.toLocaleTimeString("es-MX", {
                hour12: true,
                hour: "2-digit",
                minute: "2-digit",
            });
        },
        formatDate(date) {
            console.log(date, '----date---')
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },
        FormatTi(time) {
            const event = new Date('August 19, 2022 ' + time + ':00 ');
            console.log(event.toLocaleTimeString('en-US'))
            let st = String(event.toLocaleTimeString('en-US')).split(':');
            return st[0] + ':' + st[1] + ' ' + (st[2].split(' ')[1])
        },
        onCopy: function (e) {
            //  alert('Acabas de copiar el siguiente texto en el portapapeles: ' + e.text)
        },
        onError: function (e) {
            // alert('No se pudo copiar el texto al portapapeles')
            console.log(e);
        },
        onEventClick(event, e) {
            console.log(event)
            this.selectedEvent = event
            this.showDialog = true

            // Prevent navigating to narrower view (default vue-cal behavior).
            e.stopPropagation()
        },
        logEvents(type, event) {
            //console.log(event,'-----evento---',type)

        },
        onEventDragStart(e, draggable) {
            // Passing the event's data to Vue Cal through the DataTransfer object.
            e.stopPropagation()
            e.dataTransfer.setData('event', JSON.stringify(draggable))
            e.dataTransfer.setData('cursor-grab-at', e.offsetY)

        },
        eventoDrag(event) {
            console.log(event, '-----drag---')
            if (event.event.data.hasOwnProperty('asignacion')) {
                // event.stopPropagation();

            }

            this.returnVal(event)
            /*if (window.confirm("¿Esta segura de realizar este cambio?")) {
             
            }else{
              //cancelo
              console.log('----cancelo----')
            this.$parent.returnVal(event.originalEvent)
             
            }*/

        }
    },
    mounted() {
        // this.$refs.vuecal.switchView('day')
        console.log(this.dataempleados, '-------')
        if (this.$route.query.hasOwnProperty('cid')) {

            this.dataempleados.forEach(element => {
                if (element.data.id == this.$route.query.cid) {
                    this.selectedEvent = element;
                    this.showDialog = true;
                }
            });

        }
    },

    created() {

        console.log(this.selectedDate)

        // Place all the events in the real time current week.
        /* for (let i = 0; i < 5; i++) {
           const day = this.previousFirstDayOfWeek.addDays(i).format()

           this.demoExample.events.push(
             {
               start: `${day} 12:00`,
               end: `${day} 13:00`,
               title: 'LUNCH',
               class: 'lunch',
               background: true,
               deletable: false,
               resizable: false,
               split: 1
             },
             {
               start: `${day} 12:00`,
               end: `${day} 13:00`,
               title: 'LUNCH',
               class: 'lunch',
               background: true,
               deletable: false,
               resizable: false,
               split: 2
             }
           )
         }*/
         if(this.tipo_cita=='instalacion'){
            this.tipo='instalacion'
            this.ruta='ventas';
         }else{
            this.tipo='cita'
            this.ruta='';
         }
        if (this.$route.query.hasOwnProperty('idc')) {
            this.buscar_c = this.$route.query.idc;
            this.EdicionCreacionView = true;
            this.getDataCotizaciones();
        }
        if(this.$route.query.hasOwnProperty('idv')){
            this.buscar_c = this.$route.query.idv;
            this.EdicionCreacionView = true;
            this.getDataCotizaciones();
        }

    }
}
</script>

<style lang="css">
/*
.cardF64272  {background-color:  rgba(246, 66, 114,.30) !important; color:  rgba(246, 66, 114,3) !important;font-weight: bold; }
.cardF6648B  {background-color:  rgba(246, 100, 139,.30) !important;color:  rgba(246, 100, 139,3) !important;font-weight: bold; }
.cardF493A7  {background-color:  rgba(244, 147, 167,.30) !important;color:  rgba(244, 147, 167,3) !important; font-weight: bold;}
.cardF891A6  {background-color:  rgba(248, 145, 166,.30) !important;color:  rgba(248, 145, 166,3) !important; font-weight: bold;}
.cardFFCCD5  {background-color:  rgba(255, 204, 213,.30) !important; color:  rgba(255, 204, 213,3) !important;font-weight: bold; }
.card8b5aff  {background-color:  rgba(139, 90, 255,.30) !important; color:  rgba(139, 90, 255,3) !important;font-weight: bold; }
.carda27bff  {background-color:  rgba(162, 123, 255,.30) !important;color:  rgba(162, 123, 255,3) !important; font-weight: bold;}
.cardb99cff  {background-color:  rgba(185, 156, 255,.30) !important;color:  rgba(185, 156, 255,3) !important; font-weight: bold;}
.cardd0bdff  {background-color:  rgba(208, 189, 255,.30) !important;color:  rgba(208, 189, 255,3) !important; font-weight: bold;}
.carde8deff  {background-color:  rgba(232, 222, 255,.30) !important;color:  rgba(232, 222, 255,3) !important;font-weight: bold; }
.card51e5db  {background-color:  rgba(81, 229, 219,.30) !important; color:  rgba(81, 229, 219,3) !important; font-weight: bold;}
.card74ebe3  {background-color:  rgba(116, 235, 227,.30) !important;color:  rgba(116, 235, 227,3) !important; font-weight: bold;}
.card96f0ea  {background-color:  rgba(150, 240, 234,.30) !important;color:  rgba(150, 240, 234,3) !important;font-weight: bold; }
.cardb9f5f1  {background-color:  rgba(245, 188, 0, 0.30) !important;color:  rgba(245, 188, 0,3) !important; font-weight: bold;}
.carddcfaf8  {background-color:  rgba(220, 250, 248,.30) !important; color:  rgba(220, 250, 248,3) !important; font-weight: bold;}
.cardffa51a  {background-color:  rgba(255, 165, 26,.30)  !important; color:  rgba(255, 165, 26,3)  !important;font-weight: bold; }
.cardffb748  {background-color:  rgba(255, 183, 72,.30) !important; color:  rgba(255, 183, 72,3) !important; font-weight: bold;}

.cardffc976  {background-color:  rgba(255, 201, 118,.30) !important;color:  rgba(255, 201, 118,3) !important;font-weight: bold; }
.cardffdba3  {background-color:  rgba(255, 219, 163,.30) !important;color:  rgba(255, 219, 163,3) !important;font-weight: bold; }
.cardffedd1  {background-color:  rgba(255, 237, 209,.30) !important;color:  rgba(255, 237, 209,3) !important; font-weight: bold;}
.cardD7F9F1  {background-color:  rgba(215, 249, 241,.30) !important;color:  rgba(215, 249, 241,3) !important; font-weight: bold;}

.cardAFBC88  {background-color:  rgba(175, 188, 136,.30) !important;color:  rgba(175, 188, 136,3) !important;font-weight: bold; }
.cardF3722C  {background-color:  rgba(243, 114, 44,.30) !important;color:  rgba(243, 114, 44,3) !important;font-weight: bold; }
.cardF9C74F  {background-color:  rgba(249, 199, 79,.30) !important; color:  rgba(249, 199, 79,3) !important; font-weight: bold;}
.cardF8961E  {background-color:  rgba(248, 150, 30,.30) !important;color:  rgba(248, 150, 30,3) !important; font-weight: bold;}
.cardF94144  {background-color:  rgba(249, 65, 68,.30) !important;color:  rgba(249, 65, 68,3) !important; font-weight: bold;}
.card90BE6D  {background-color:  rgba(144, 190, 109,.30) !important;color:  rgba(144, 190, 109,3) !important;font-weight: bold; }
.card7678ed  {background-color:  rgba(118, 120, 237,.30) !important;color:  rgba(118, 120, 237,3) !important; font-weight: bold;}
.cardff595e  {background-color:  rgba(255, 89, 94,.30) !important;color:  rgba(255, 89, 94,3) !important; font-weight: bold;}
.cardefc3e6  {background-color:  rgba(239, 195, 230,.30) !important;color:  rgba(239, 195, 230,3) !important;font-weight: bold; }

.card9df7e5  {background-color:  rgba(157, 247, 229,.30) !important;color:  rgba(157, 247, 229,1) !important; font-weight: bold;}
.carda7c957  {background-color:  rgba(167, 201, 87,.30) !important;color:  rgba(167, 201, 87,1) !important;font-weight: bold; }
.cardcae9ff  {background-color:  rgba(202, 233, 255,.30) !important;color:  rgba(202, 233, 255,1) !important; font-weight: bold;}
.card73d2de  {background-color:  rgba(115, 210, 222,.30) !important;color:  rgba(115, 210, 222,1) !important; font-weight: bold;}
.card7ae582  {background-color:  rgba(122, 229, 130,.30) !important;color:  rgba(122, 229, 130,1) !important;font-weight: bold;}
.card64b5f6  {background-color:  rgba(100, 181, 246,.30) !important;color:  rgba(100, 181, 246,1) !important;font-weight: bold; }
.cardff7f51  {background-color:  rgba(255, 127, 81,.30) !important; color:  rgba(255, 127, 81,1) !important;font-weight: bold; }
.cardf3dfa2  {background-color:  rgba(243, 223, 162,.30) !important;color:  rgba(243, 223, 162,1) !important; font-weight: bold;}
.cardcbe896  {background-color:  rgba(203, 232, 150,.30) !important;color:  rgba(203, 232, 150,1) !important; font-weight: bold;}
.cardb298dc  {background-color:  rgba(178, 152, 220,.30) !important; color:  rgba(178, 152, 220,1) !important;font-weight: bold; }
.cardf6aa1c  {background-color:  rgba(246, 170, 28,.30) !important;color:  rgba(246, 170, 28,1) !important;font-weight: bold; }
.card00f5d4  {background-color:  rgba(0, 245, 212,.30) !important;color:  rgba(0, 245, 212,3) !important; font-weight: bold;}
.cardff86c8  {background-color:  rgba(255, 134, 200,.30) !important;color:  rgba(255, 134, 200,1) !important; font-weight: bold;} 
.cardffa3a5  {background-color:  rgba(255, 163, 165,.30) !important;color:  rgba(255, 163, 165,1) !important; font-weight: bold;}
.card9bb1ff  {background-color:  rgba(155, 177, 255,.30) !important;color:  rgba(155, 177, 255,1) !important; font-weight: bold;}
*/

.cardF64272 {
    background-color: rgba(246, 66, 114, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF6648B {
    background-color: rgba(246, 100, 139, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF493A7 {
    background-color: rgba(244, 147, 167, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF891A6 {
    background-color: rgba(248, 145, 166, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardFFCCD5 {
    background-color: rgba(255, 204, 213, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card8b5aff {
    background-color: rgba(139, 90, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.carda27bff {
    background-color: rgba(162, 123, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardb99cff {
    background-color: rgba(185, 156, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardd0bdff {
    background-color: rgba(208, 189, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.carde8deff {
    background-color: rgba(232, 222, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card51e5db {
    background-color: rgba(81, 229, 219, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card74ebe3 {
    background-color: rgba(116, 235, 227, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card96f0ea {
    background-color: rgba(150, 240, 234, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardb9f5f1 {
    background-color: rgba(245, 188, 0, 0.30) !important;
    color: black !important;
    font-weight: bold;
}

.carddcfaf8 {
    background-color: rgba(220, 250, 248, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffa51a {
    background-color: rgba(255, 165, 26, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffb748 {
    background-color: rgba(255, 183, 72, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffc976 {
    background-color: rgba(255, 201, 118, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffdba3 {
    background-color: rgba(255, 219, 163, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffedd1 {
    background-color: rgba(255, 237, 209, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardD7F9F1 {
    background-color: rgba(215, 249, 241, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardAFBC88 {
    background-color: rgba(175, 188, 136, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF3722C {
    background-color: rgba(243, 114, 44, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF9C74F {
    background-color: rgba(249, 199, 79, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF8961E {
    background-color: rgba(248, 150, 30, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardF94144 {
    background-color: rgba(249, 65, 68, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card90BE6D {
    background-color: rgba(144, 190, 109, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card7678ed {
    background-color: rgba(118, 120, 237, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardff595e {
    background-color: rgba(255, 89, 94, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardefc3e6 {
    background-color: rgba(239, 195, 230, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card9df7e5 {
    background-color: rgba(157, 247, 229, .30) !important;
    color: black !important;
    font-weight: bold;
}

.carda7c957 {
    background-color: rgba(167, 201, 87, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardcae9ff {
    background-color: rgba(202, 233, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card73d2de {
    background-color: rgba(115, 210, 222, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card7ae582 {
    background-color: rgba(122, 229, 130, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card64b5f6 {
    background-color: rgba(100, 181, 246, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardff7f51 {
    background-color: rgba(255, 127, 81, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardf3dfa2 {
    background-color: rgba(243, 223, 162, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardcbe896 {
    background-color: rgba(203, 232, 150, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardb298dc {
    background-color: rgba(178, 152, 220, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardf6aa1c {
    background-color: rgba(246, 170, 28, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card00f5d4 {
    background-color: rgba(0, 245, 212, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardff86c8 {
    background-color: rgba(255, 134, 200, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardffa3a5 {
    background-color: rgba(255, 163, 165, .30) !important;
    color: black !important;
    font-weight: bold;
}

.card9bb1ff {
    background-color: rgba(155, 177, 255, .30) !important;
    color: black !important;
    font-weight: bold;
}

.cardcambiosucursal {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #e7900e 10px, #f2f2f2 20px);
    /* IE 10+ */
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardfinalizada {
    background-color: #15a34a !important;
    color: white !important
}

.vuecal__event {
    font-size: 12px;
    border-radius: 6px;
    border: 2px solid white
}

.vuecal .day-split-header {
    font-size: 11px;
}

.vuecal__body .split1,
.vuecal__body .split2,
.vuecal__body .split3,
.vuecal__body .split4,
.vuecal__body .split5,
.vuecal__body .split6,
.vuecal__body .split7,
.vuecal__body .split8,
.vuecal__body .split9,
.vuecal__body .split10,
.vuecal__body .split12,
.vuecal__body .split13,
.vuecal__body .split14 {
    background: #ffffffc9;
    /*background: #ffcb0061; 
border:1px solid #fff;*/
    border: 1px solid #e5e7eb;
}

.split-label {
    padding-top: 10px;
    color: rgb(22 101 52);
    font-weight: bold;
}

.split-label2 {

    color: rgb(124 107 37);
}

.vuecal__time-column {
    background: white;
    color: rgba(0, 0, 0, .7) !important
}

/*.split2 .vuecal__event {background-color: rgb(5, 5, 5);

}*/

.vuecal__no-event {
    display: none;
}

.hours::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid rgb(5, 5, 5);
}

.minutesline::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    /* border-top: 1px solid rgba(0, 68, 255, 0.836)*/
}
</style>
