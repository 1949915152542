
import {
    useInfoStore
} from '../stores/info'
import {
    emitter
} from "@/mitt.js";
export default {
   
  data() {
    const info = useInfoStore()
    return {
        infositio: info.getAll(),
       a:0,
       m:0,
        cliente_s: '',
        month: this.getYear() + '-' + this.getMesIndex(),
        datesel: this.actualformatDate(),
        mes: {

            "month": this.getmes(),
            monthIndex: this.getMesIndex(),
            year: this.getYear(),
            selectedYear: this.getYear(),
        },
       
    };
  },

  created() {},

  methods: {
    actualformatDate() {
        var d = new Date(),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    },

    setBackDate() {
        var day = new Date(this.datesel);
        console.log(day); // Apr 30 2000

        let daynew = new Date(day);
        daynew.setDate(day.getDate());
        this.datesel = this.formatDate(daynew);
        this.getData();
    },
    formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    },
    setNextDate() {
        var day = new Date(this.datesel);
        console.log(day); // Apr 30 2000

        let daynew = new Date(day);
        daynew.setDate(day.getDate() + 2);
        this.datesel = this.formatDate(daynew);
        this.getData();
    },
   
    getYear() {
        if (this.$route.query.hasOwnProperty('a')) {
            return this.$route.query.a;
            //await this.getDataCotizacion();
            // this.formatearCotizacionEditada();
        } else {
            return new Date().getFullYear();
        }

    },
    getMesIndex() {
        if (this.$route.query.hasOwnProperty('m')) {
            return this.$route.query.m;
            //await this.getDataCotizacion();
            // this.formatearCotizacionEditada();
        } else {
            return new Date().getMonth() + 1
        }

    },

    getmes() {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        if (this.$route.query.hasOwnProperty('m')) {
            return monthNames[this.$route.query.m];

        } else {
            const d = new Date();
            return monthNames[d.getMonth()];
        }
    },
getColor(status, status_instalacion) {
    //cita.status
    switch (status) {
        case 'realizada':

            if (status_instalacion == 'rechazada') {
                return 'black'
            } else {
                return 'green'
            }

            break;
        case 'agendada':
            return 'blue'
            break;
        case 'confirmada':
            return 'lime'
            break;
        case 'cancelada':
            return 'red'
            break;
        case 'reagendada':
            return 'pink'
            break;
        case 'no atendida':
            return 'yellow'
            break;

        default:
            break;
    }
},
parseTimeN(date) {
    const event = new Date(date);

    return event.toLocaleTimeString("es-MX", {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
    });
},
getColorTipo(tipo) {
    switch (tipo) {
        case 'cita':
            return 'bg-green-500'
            break;
        case 'pendiente':
            return 'bg-red-500'
            break;

        default:
            return 'bg-blue-500'
            break;
    }
},
getIcono(tipo) {
    switch (tipo) {
        case 'cita':
            return 'la-user-astronaut'
            break;
        case 'pendiente':
            return 'la-business-time'
            break;
        case 'instalacion':
            return 'la-tools'
            break;
        case 'medidas':
            return 'la-ruler-combined'
            break;
            case 'liquidacion':
            return 'la-file-invoice-dollar'
            break;
            case 'anticipo':
                return 'la-money-bill-wave'
                break;
                case 'supervicion_instalacion':
                    return 'la-list-alt'
                    break;
                
        default:
            break;
    }
}
  }
}