<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-start  overflow-hidden">

    <div v-if="!IsMov()" class="w-full h-24 bg-gray-100 flex flex-wrap content-center justify-start px-2">
        <div class="w-42 px-4 ">
            <el-date-picker class="w-full" v-model="fecha" type="daterange" unlink-panels range-separator="-" start-placeholder="De" end-placeholder="Hasta" @change="getData('fecha')" />

        </div>
        <div class="">
            <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data['per_page']" :total="Number(data['total'])" @current-change="pageChangue" class="" />

        </div>

    </div>
    <div v-if="IsMov()" class="w-full h-24 bg-gray-100 flex flex-wrap content-center justify-end px-2 ">

        <div class="w-10/12 h-12 justify-end content-center flex flex-wrap ">
            <el-pagination v-model:currentPage="pagina" small background layout="prev, pager, next" :page-size="data['per_page']" :total="Number(data['total'])" @current-change="pageChangue" class="" />

        </div>
        <div class="w-full h-12 justify-center content-center flex flex-wrap ">
            <el-date-picker class="w-full" v-model="fecha" type="daterange" unlink-panels range-separator="-" start-placeholder="De" end-placeholder="Hasta" @change="getData('fecha')" />

        </div>

    </div>

    <Tabla v-bind:key="reftable" v-if="data.hasOwnProperty('data')" :columns="headings" :add="false" :rows="data.data" :filtros="['id','clientes','vendedores']" :buscarp="buscar" :filtroApi="true" :paginate="true" :page="{
        page:data.current_page,
        total:data.last_page
      }" />

    <el-drawer v-model="addAbono" title="" :with-header="false" size="50%">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full bg-gray-100 outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

            <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">
                <h3 class="text-3xl font-semibold">
                    Crear abono
                </h3>
                <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                    <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                </button>
            </div>
            <!--body-->
            <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full py-10">
                <div class=" flex flex-wrap w-full" v-bind:key="reftable">
                    <div class=" px-3 w-1/2">
                        <div class="mb-5">
                            <label for="fName" class="mb-3 block text-base font-medium text-[#07074D]">
                                Tipo metodo
                            </label>
                            <select @change="ActualizarValorMetodo()" v-model="tipo_metodo" class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 text-center text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md">
                                <option value="0">Seleccione un metodo</option>
                                <option v-for="(row,index) in metodos_data" v-bind:key="index" :value="row">{{row.nombre}}</option>

                            </select>
                        </div>
                    </div>
                    <div class=" px-3 w-1/2">
                        <div class="mb-5">
                            <label for="fName" class="mb-3 block text-base font-medium text-[#07074D]">
                                Factura
                            </label>
                            <select  v-model="obj_abono.factura" class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 text-center text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md">
                                <option value="0">Seleccione un metodo</option>
                                <option v-for="(row,index) in [{id:1,text:'Si'},{id:0,text:'No'}]" v-bind:key="index" :value="row.id">{{row.text}}</option>

                            </select>
                        </div>
                    </div>
                    
                    <div class=" px-3 w-1/2">
                        <div class="mb-5">
                            <label for="fName" class="mb-3 block text-base font-medium text-[#07074D]">
                                % Impuesto
                            </label>
                            <input type="text" disabled v-model="obj_abono.impuesto" placeholder="First Name" class="w-full rounded-md border border-[#e0e0e0] bg-gray-200 py-3  text-center text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                        </div>
                    </div>
                </div>
                <div class=" flex flex-wrap w-full">

                    <div class=" px-3  w-1/2">
                        <div class="mb-5">
                            <label for="lName" class="mb-3 block text-base font-medium text-[#07074D]">
                                Total Abono
                            </label>
                            <input type="number" placeholder="Total Abono" v-model="obj_abono.total" class="w-full  rounded-md border border-[#e0e0e0] text-center py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <!---@input="verificarTotalAbono($event)"-->
                <btn_component :text="'Registrar abono'" :tipo="'success'" @click="guardarAbono()"></btn_component>
                <btn_component :text="'Cerrar'" :tipo="'info'" @click="addAbono=false"></btn_component>

                <!--<el-button @click="addAbono=false">Cancelar</el-button>
                <el-button @click="guardarAbono()" type="primary">Registrar abono</el-button>-->

            </div>
        </template>
    </el-drawer>
    <el-drawer @closed="getData('cerrar pagos')" v-model="viewCotizacionAbonos" title="" :with-header="false" :size="$isMobile()?'100%':'60%'">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

            <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">
                <h3 class="text-3xl font-semibold">
                    Lista de pagos
                </h3>
                <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                    <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                </button>
            </div>
            <!--body-->
            <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

                <div class="h-full bg-gray-100 py-2 flex flex-wrap w-full content-start px-2 ">

                    <div v-if="(el_cotizacion.venta!=null)" class="w-full text-left text-red-500 mt-3">
                        <span class="font-extrabold text-red-500">Saldo pendiente :</span> ${{formatPrice(Number(CalcularDeuda()).toFixed(2)) }}
                    </div>
                    <a v-if="CalcularDeuda()>0&&(el_cotizacion.venta!=null)" class="my-3 text-blue-500" @click="addAbono=true;obj_abono.cotizacion=el_cotizacion.id;">
                        Registrar abono
                    </a>
                    <div v-if="(el_cotizacion.venta!=null)" class="flex flex-wrap justify-between h-48 bg-gray-300 mt-2 overflow-y-scroll w-full content-start">

                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Total</th>
                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Capital</th>
                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Factura</th>
                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Impuestos</th>
                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Tipo abono</th>

                                    <th class="border-2 top-0 sticky border-black bg-gray-200">Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(row_abono,index_abono) in el_cotizacion.abonos" v-bind:key="index_abono">
                                    <tr>
                                        <td class="border border-black text-blue-500 font-extrabold">${{formatPrice((Number(row_abono.capital)+Number(row_abono.impuestos)).toFixed(0))}}</td>
                                        <td class="border border-black text-green-500 font-extrabold">${{formatPrice((Number(row_abono.capital)).toFixed(2))}}</td>
                                        <td class="border border-black text-green-500 font-extrabold">{{row_abono.factura?'Si':'No'}}</td>
                                        <td class="border border-black text-yellow-600 font-extrabold">${{formatPrice(Number(row_abono.impuestos).toFixed(2))}}</td>

                                        <td class="border border-black text-yellow-600 font-extrabold">{{row_abono.tipo_pago}}</td>

                                        <td @click="eliminarabono(row_abono.id)" class="border cursor-pointer border-black text-red-500 font-extrabold">Eliminar</td>

                                    </tr>
                                </template>

                            </tbody>
                        </table>
                    </div>

                </div>

            </div>

        </div>
        <template #footer>
            <div class="dialog-footer flex flex-wrap justify-between">

                <el-popconfirm confirm-button-text="Si" cancel-button-text="No" :icon="InfoFilled" icon-color="#626AEF" title="¿Esta seguro de convertir en pedido?" @confirm="AVenta()">
                    <template #reference>
                        <button v-if="el_cotizacion.venta==null||el_cotizacion.venta==''" class="my-1 bg-yellow-500 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Convertir en pedido</button>

                    </template>
                </el-popconfirm>
                <!--<a v-if="(el_cotizacion.venta!=null&&el_cotizacion.venta!='')&&(CalcularDeuda()<(Number(el_cotizacion.total)/2))&&el_cotizacion.status_verificar!='APROBADA'" class="my-1 w-3/12 bg-yellow-500 text-black text-xs text-center font-medium px-2 py-1 rounded border-2  border-black" target="_blank" :href="'/app/nueva/cotizacion?select='+el_cotizacion.id+'&a=produccion'">Enviar a formato</a>-->

                <btn_component v-if="(el_cotizacion.venta!=null&&el_cotizacion.venta!='')&&(CalcularDeuda()<(Number(el_cotizacion.total)/2))&&el_cotizacion.status_verificar!='APROBADA'" :text="'Enviar a formato'" :tipo="'clasic'" @click="$router.push('/app/nueva/cotizacion?select='+el_cotizacion.id+'&a=produccion')"></btn_component>

                <!--<button @click="viewCotizacionAbonos=false" class="my-1 bg-blue-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Cerrar detalle</button>-->

                <btn_component :text="'Cerrar'" :tipo="'info'" @click="viewCotizacionAbonos=false"></btn_component>
            </div>
        </template>
    </el-drawer>

    <el-drawer @closed="getData('citas')" v-model="viewListCitas" title="" :with-header="false" :size="$isMobile()?'100%':'60%'">
        <!--content-->
        <div class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none overflow-y-scroll">
            <!--header-->

            <div class="sticky top-0 flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">

            </div>
            <!--body-->
            <div class="w-full   overflow-y-scroll flex  flex-wrap justify-start  content-start z-20 h-full">

                <div class="h-full bg-white px-2 flex flex-wrap w-full justify-start">

                    <div class="h-full overflow-y-scroll">
                        <TemplateCitas :cotizacion="el_cotizacion"></TemplateCitas>

                    </div>
                </div>

            </div>

        </div>
        <template #footer>
            <div class="dialog-footer">
                <btn_component :text="'Cerrar'" :tipo="'info'" @click="viewListCitas=false"></btn_component>

                <!--<button @click="viewListCitas=false" class="my-1 bg-blue-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Cerrar detalle</button>-->

            </div>
        </template>
    </el-drawer>
    <el-drawer @closed="getData('cotizacion view')" v-model="viewCotizacion" title="" :with-header="false" :size="$isMobile()?'100%':'100%'" class="py-0">
        <!--content-->
        <div v-if="el_cotizacion.id!=0" class="h-full    flex flex-wrap content-start justify-center w-full  outline-none focus:outline-none ">
            <!--header-->

           
            <!--body-->
            <div class=" h-auto sticky  flex flex-wrap justify-between p-5 border-b border-solid border-blueGray-200  z-30 bg-white rounded-t w-full">
                <!--<h3 class="text-3xl font-semibold">
                    {{el_cotizacion.venta==null||el_cotizacion.venta==''?'Cotización':'Pedido'}}
                </h3>-->
    

                <div class=" flex flex-wrap justify-start w-full bg-white  py-2 h-auto">


                    <btn_component :text="'formato con medidas'" :tipo="'purple'" @click="tipo_formato='con_medidas'"></btn_component>
        
                    <btn_component :text="'formato sin medidas'" :tipo="'info'" @click="tipo_formato='sin_medidas'"></btn_component>
        



                    <el-popconfirm confirm-button-text="Si" cancel-button-text="No" :icon="InfoFilled" icon-color="#626AEF" title="¿Esta seguro de convertir en pedido?" @confirm="AVenta()">
                        <template #reference>
                            <!--<btn_component v-if="el_cotizacion.venta==null||el_cotizacion.venta==''" :text="'Convertir en pedido'" :tipo="'warning'"   ></btn_component>-->
                            <button v-if="el_cotizacion.venta==null||el_cotizacion.venta==''" class="my-1 bg-yellow-500 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Convertir en pedido</button>
                            <!--<btn_component v-if="el_cotizacion.venta==null||el_cotizacion.venta==''" :text="'Convertir en pedido'" :tipo="'warning'"   ></btn_component>-->
        
                        </template>
                    </el-popconfirm>
        
                    <!--<a v-if="(el_cotizacion.venta!=null||el_cotizacion.venta!='')&(CalcularDeuda()<(Number(el_cotizacion.total)/2))&&el_cotizacion.status_verificar!='APROBADA'" class="my-1 w-3/12 bg-yellow-500 text-black font-medium px-2 py-1 rounded border-2  border-black" target="_blank" :href="'/app/nueva/cotizacion?select='+el_cotizacion.id+'&a=produccion'">Enviar a formato</a>-->
        
                    <!--<button @click="editarobservacion=true" class="my-1 bg-blue-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Editar Observaciones</button>
        
                    <button @click="onCapture" class="my-1 bg-blue-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Descargar cotizacion</button>
                    <button @click="viewCotizacion=false" class="my-1 bg-blue-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Cerrar detalle</button>-->
        
                    <btn_component v-if="(el_cotizacion.venta!=null||el_cotizacion.venta!='')&(CalcularDeuda()<(Number(el_cotizacion.total)/2))&&el_cotizacion.status_verificar!='APROBADA'" :text="'Enviar a formato'" :tipo="'warning'" @click="$router.push('/app/nueva/cotizacion?select='+el_cotizacion.id+'&a=produccion')"></btn_component>
        
                    <btn_component :text="'Editar Descuento'" :tipo="'purple'" @click="editarDescuento=true"></btn_component>
                    <btn_component :text="'Agregar link archivos'" :tipo="'clasic'" @click="editarlink_archivos=true"></btn_component>
                    <btn_component :text="'Editar Observaciones'" :tipo="'clasic'" @click="editarobservacion=true"></btn_component>
                    <btn_component :text="'Descargar cotizacion'" :tipo="'success'" @click="DescargarCotizacion()"></btn_component>
        
                    <btn_component v-if="(el_cotizacion.id>0)" :count="el_cotizacion.emails_cotizacion.length" :text="'Enviar por correo'" :tipo="'purple'" @click="enviarCorreoCot"></btn_component>
        
                    <btn_component :text="'Cerrar'" :tipo="'info'" @click="viewCotizacion=false"></btn_component>
        
                </div>



            </div>
            <div class="w-full    flex  flex-wrap justify-start  content-start z-20 max-h-max">
                
                <div class="h-full bg-white px-2 flex flex-wrap w-full justify-start " >

                    <div v-if="editarDescuento" class="w-full flex flex-wrap justify-start content-start">
                        <span class="text-black font-medium">Descuento</span>
                        <input type="numeric" @input="formatNumberAnd0($event)" class="h-20 text-left mb-2 w-full px-2 bg-gray-100" v-model="el_cotizacion.descuento">
                        <span class="text-black font-medium">Descuento personalizado</span>
                        <input type="text" placeholder="Concepto descuento personalizado"  class=" text-left mb-2 w-full px-2 bg-gray-100" v-model="el_cotizacion.concepto_descuento_personalizado">
                        <input type="numeric" placeholder="Descuento personalizado" @input="formatNumberAnd0($event)" class="h-20 text-left mb-2 w-full px-2 bg-gray-100" v-model="el_cotizacion.descuento_personalizado">

                        <button @click="editarDescuento=false" class="my-1 bg-blue-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Cancelar</button>
                        <button @click="guardarDescuento()" class="my-1 bg-green-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Guardar</button>
                    </div>
                    <div v-if="editarobservacion" class="w-full flex flex-wrap justify-start content-start">
                        <span class="text-black font-medium">Observaciones</span>
                        <textarea class="h-20 text-left mb-2 w-full px-2 bg-gray-100" v-model="el_cotizacion.observaciones"></textarea>
                        <span class="text-black font-medium">Datos Cotización</span>
                        <textarea class="h-20 text-left w-full px-2 bg-gray-100" v-model="el_cotizacion.datos_cotizacion"></textarea>

                        <button @click="editarobservacion=false" class="my-1 bg-blue-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Cancelar</button>
                        <button @click="guardar()" class="my-1 bg-green-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Guardar</button>
                    </div>
                    <div v-if="editarlink_archivos" class="w-full flex flex-wrap justify-start content-start">
                        <span class="text-black font-medium">Url visualizacion de archivos</span>
                        <textarea class="h-20 text-left mb-2 w-full px-2 bg-gray-100" v-model="el_cotizacion.url_files"></textarea>
                       <button @click="editarlink_archivos=false" class="my-1 bg-blue-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Cancelar</button>
                        <button @click="guardarLink()" class="my-1 bg-green-200 mx-1 px-2 py-1 text-xs font-medium rounded border-2  border-black">Guardar</button>
                    </div>
                    
                    <div v-if="!editarobservacion&&!editarDescuento" class=" h-full w-full flex flex-wrap   content-start overflow-y-scroll ">
                       


                        <div v-if="(el_cotizacion.venta!=null)" class="cursor-pointer" @click="viewCotizacion=false;viewCotizacionAbonos=true">

                            <span class="font-extrabold text-red-500">Ver Saldo pendiente :</span> <span class="font-extrabold">${{formatPrice(Number(CalcularDeuda()).toFixed(2)) }}</span>

                        </div>
                        <TemplateCotizacion :tipo_formato="tipo_formato" :cotizacion="el_cotizacion" :infoneg="true" :dn="true" :dc="true" :dtotal="true" :dqr="true" :ddt="true"></TemplateCotizacion>


                     


                    </div>
                </div>

            </div>

            

        </div>
        <!--<template #footer>
           
        </template>-->
       
    </el-drawer>





    <el-dialog v-model="ModalCotizacionFinal" title="Asignar cotizacion final" :width="$isMobile()?'100%':'30%'">
        <div class="w-full px-2 flex flex-wrap    justify-start">
            <div class="mb-5 w-full">
                <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                    Ingresa la cotizacion final
                </label>
               <input class="h-12 w-full text-black rounded bg-gray-200 p-2 border-0" v-model="final"  placeholder="Cotización">
            </div>

        </div>
        <template #footer>
            <span class="dialog-footer">
                <!--<el-button @click="ModalCambiarVendedor = false">Cancel</el-button>
                <el-button type="primary" @click="actualizarVendedor()">
                    Guardar
                </el-button>-->

                <btn_component :text="'Guardar'" :tipo="'success'" @click="set_final()"></btn_component>
                <btn_component :text="'Cerrar'" :tipo="'info'" @click="ModalCotizacionFinal = false"></btn_component>

            </span>
        </template>
    </el-dialog>

    

    <el-dialog v-model="ModalCambiarVendedor" title="Cambiar vendedor" :width="$isMobile()?'100%':'30%'">
        <div class="w-full px-2 flex flex-wrap    justify-start">
            <div class="mb-5 w-full">
                <label for="fName" class="mb-3 block text-base font-medium   text-left text-[#07074D]">
                    Vendedor cotización
                </label>
                <SearchSelectUsuariocitas v-bind:key="usuario_vendedor" @carga="buscarGetUsuarios" :rows="usuarios_data" :label="'Vendedor'" :valor="usuario_vendedor" @seleccionado="action_usuario" />

            </div>

        </div>
        <template #footer>
            <span class="dialog-footer">
                <!--<el-button @click="ModalCambiarVendedor = false">Cancel</el-button>
                <el-button type="primary" @click="actualizarVendedor()">
                    Guardar
                </el-button>-->

                <btn_component :text="'Guardar'" :tipo="'success'" @click="actualizarVendedor()"></btn_component>
                <btn_component :text="'Cerrar'" :tipo="'info'" @click="ModalCambiarVendedor = false"></btn_component>

            </span>
        </template>
    </el-dialog>

</section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import Tabla from "@/components/Tabla.vue";
import TemplateCotizacion from "@/components/TemplateCotizacion.vue";
import SearchSelectUsuariocitas from "@/components/SearchSelectUsuariocitas.vue";
import TemplateCitas from "@/components/TemplateCitas.vue";
import generales from "@/functions/generales";
import cotizaciones_g from "@/functions/cotizaciones";
import metodos_pagos from "@/Services/metodos_pagos";
import abonos from "@/Services/abonos";
import usuarios from "@/Services/user";
import cotizaciones from "@/Services/cotizaciones";

import domtoimage from "dom-to-image-more";
import {
    filter
} from "lodash";
export default {
    components: {
        Tabla,
        TemplateCotizacion,
        TemplateCitas,
        SearchSelectUsuariocitas

    },
    mixins: [generales, cotizaciones, metodos_pagos, abonos, cotizaciones_g],
    data() {
        const user = useUserStore()

        return {
            headings: [

                {
                    key: 'id',
                    name: '# Cotizacion',
                    class: 'min-w-[100px]',
                    show: true
                },
                {
                    key: 'seleccionar_pedido',
                    name: 'Cotizacion final',
                    show: true,
                    class:'min-w-[200px] Text-blue-500'
                },
                /* {
                     key: 'idventa',
                     name: '# venta',
                     show: true
                 },*/
                {
                    key: 'fecha_ago',
                    name: 'Fecha C',
                    show: true
                },

                {
                    key: 'clientes',
                    name: 'Nom Cliente',
                    show: true
                },

                {
                    key: 'usuario',
                    name: 'Vendedor',
                    show: true
                },
                {
                    key: 'cambiar_vendedor',
                    name: 'Cambiar vendedor',
                    show: true
                },
               

                /* {
                     key: 'subtotal',
                     name: 'Subtotal',
                     show: true
                 },*/
                {

                    key: 'descuento',
                    name: 'Descuento',
                    show: true
                },
                {
                    key: 'total',
                    name: 'Total Venta',
                    show: true
                },
                /*{
                    key: 'saldo',
                    name: 'Saldo Pendiente',
                    show: true
                },*/
                {
                    key: 'citas',
                    name: 'Lista Citas',
                    show: true

                },
                {

                    key: 'ver_cotizacion',
                    name: 'Ver Detalle',
                    show: true
                },
                {

                    key: 'ver_citas',
                    name: 'Ver citas',
                    show: true
                },

                {

                    key: 'editar_cot',
                    name: 'Editar cotización',
                    show: true
                },
                {

                    key: 'crear_cita',
                    name: 'Crear cita',
                    show: true
                },
                {

                    key: 'enviar_whatsapp',
                    name: 'Enviar whatsapp',
                    show: true
                },

                /* {
                     key: 'edit',
                     name: 'Editar',
                     show: true
                 },*/
                {
                    key: 'eliminar_cotizacion',
                    name: 'Acción',
                    show: true
                }
            ],
            dates: [],
            upbuton: 0,
            el_cotizacion: {
                id: 0
            },
            open: false,
            ModalCambiarVendedor: false,
            editarobservacion: false,
            editarDescuento: false,
            editarlink_archivos:false,
            user: user.getAll(),

            data: {},
            actionModal: null,
            viewCotizacion: false,
            showM: false,
            info: '',
            id: 0,

            tipo: 'cotizaciones',
            tipo_formato: 'con_medidas',
            tipo_metodo: null,
            ModalCotizacionFinal:false,
            final:null,
            //busacador usuario
            usuarios_data: [],
            usuario_vendedor: null,
            buscar_usuario: 'all',
            pagina_usuario: 1,
            limit_usuario: 0,
            //en buscador usuario

            buscar: 'all',
            pagina: 1,
            limit: 0,
            reftable: 0,
            viewCotizacionAbonos: false,
            viewListCitas: false,
            fecha: [this.getFirstDayOfMonth(), this.getLastDayOfMonth()],

        }

    },
    mounted() {

    },
    async created() {
        if (this.$route.query.hasOwnProperty('cid')) {
            this.buscar = this.$route.query.cid;
        }

        await this.getData('crear')
        await this.getDataMetodos();
        await this.getDataUsuarios();
    },
    methods: {
        async DescargarCotizacion() {

            let nombre_cliente = this.verfiNull(this.el_cotizacion.clientes.name) + ' ' + this.verfiNull(this.el_cotizacion.clientes.apellido_p) + ' ' + this.verfiNull(this.el_cotizacion.clientes.apellido_m);

            let titulotipo = (this.el_cotizacion.idventa == null) ? this.el_cotizacion.id : this.el_cotizacion.idventa;
            let name = '#' + titulotipo + ' -- ' + nombre_cliente;

            await this.onCapture(name, false, .7)
        },
        async enviarCorreoCot() {

            let data = {
                titulo: this.verfiNull(this.el_cotizacion.clientes.name) + ' ' + this.verfiNull(this.el_cotizacion.clientes.apellido_p) + ' ' + this.verfiNull(this.el_cotizacion.clientes.apellido_m),
                correo: this.el_cotizacion.clientes.email, //'popcing.agency@gmail.com',//this.el_cotizacion.clientes.email,
                id: this.el_cotizacion.id,
            }

            let enviarCorreop = 1;
            let IsSend = await this.onCapture(data.titulo,false, .7, enviarCorreop, data)
            if (IsSend == false) {
                emitter.emit("alerta_show", 'Ocurrio un error al enviar correo');
            } else {
                this.el_cotizacion.emails_cotizacion.push({
                    id: true
                })

                emitter.emit("success", 'El Correo a sido enviado');

            }

        },
        action_usuario(ev) {
            this.usuario_vendedor = ev.value;

        },
        buscarGetUsuarios(busqueda) {
            this.buscar_usuario = busqueda;
            this.getDataUsuarios();

        },

        SetCotizacionFinal(row){
            this.el_cotizacion=row;
            this.ModalCotizacionFinal=true;
        },
        
        async set_final() {

let dat = {
    id: this.el_cotizacion.id,
    id_cotizacion: this.final,
   
}

await cotizaciones.SET_FINAL(dat).then(response => {

    if (response != null && !response.hasOwnProperty('error')) {

      this.getData();
      this.ModalCotizacionFinal=false;

        //this.$router.push('/app/'); 
    } else {

        // emitter.emit("alerta_show",response.msg);

    }

});
},

        async getDataUsuarios() {

            let dat = {
                search: this.buscar_usuario,
                page: this.pagina_usuario,
                limit: this.limit_usuario,
                data: {}
            }

            await usuarios.GET_USUARIOS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.usuarios_data = response.data;

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }

            });
        },
        cambiarUsuario(row) {
            this.el_cotizacion = row;
            console.log(this.el_cotizacion.usuario, '------usaurio-----')
            this.usuario_vendedor = this.el_cotizacion.usuario;
            this.ModalCambiarVendedor = true;
        },

        IsMov() {

            //return true;
            return this.$isMobile()
        },
        viewCitas(data) {
            console.log(data.citas, '---de')
            this.viewListCitas = true;
            this.el_cotizacion = data;
        },

        verCotizacion(row) {
            console.log(row)
        this.getDataCotizacionId(row.id);
            this.el_cotizacion = row;
          

        },
        async pageChangue(val) {
            debugger;
            console.log(val, '----p---')
            this.page = val;
            await this.getData('cambio pagina')

        },

        infoModal(row) {
            this.info = row;
            console.log(row, '---info---')
        },
        paginaSearch(pagina) {
            debugger;
            console.log('---- dat---' + pagina)
            this.pagina = pagina;

            this.getData('pagina busqueda');
        },

        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        buscador(row) {
            if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                return true;
            } else {
                return false;
            }
        },
        getColumns() {
            let col = 0;
            this.headings.forEach(element => {
                if (element.show) {
                    col = col + 1
                }
            });
            return col;
        },
        limpiar() {
            this.id = 0;
            this.email = '';
            this.password = '';
            this.phone = '';
            this.name = '';
            this.role = 'cliente';

        },

        async actualizarVendedor() {
            if (this.usuario_vendedor == null) {

                emitter.emit("alerta_show", 'Es necesario selecionar un vendedor');
                return;
            }
            let dat = {
                id: this.el_cotizacion.id,
                users_id: this.usuario_vendedor.id
            }
            await cotizaciones.EDITAR_PARAMS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.ModalCambiarVendedor = false;
                    this.getData('editar');

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },

        buscarGet(text) {
            this.buscar = text;
            if (text == '') {
                this.buscar = 'all';
            }
            this.getData('buscar');
        },



        async getDataCotizacionId(id) {
            emitter.emit("loading", true)

            let dat = {
                id: id
            }

            let r = await cotizaciones.SHOW(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion = response;

                    this.viewCotizacion = true;
                    emitter.emit("loading", false)
                    //this.$router.push('/app/'); 
                } else {
                    console.log(response)
                    emitter.emit("alerta_show", response.error);
                    emitter.emit("loading", false)
                }

                return;
            });
            return r;
        },




        async getData(tipo) {
            console.log('entre---', tipo)
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)
            let dat = {
                search: this.buscar,
                page: this.pagina,
                inicio: this.buscar == 'all' ? this.formatDateEN(this.fecha[0]) : '2021-01-28',
                fin: this.formatDateEN(this.fecha[1]),
                tipo: this.tipo,
               
                data: {}
            }

            await cotizaciones.VISTA_COTIZACIONES(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response;

                    if (this.$route.query.hasOwnProperty('cid')) {
                        let fresult = response.data.filter(r => r.id == this.$route.query.cid)

                        if (fresult.length > 0) {
                            let tv = this.$route.query.hasOwnProperty('tv') ? this.$route.query.tv : '';
                            this.el_cotizacion = fresult[0];
                            if (tv == 'pagos') {
                                //vista de abonos
                                this.viewCotizacionAbonos = true;
                            } else if (tv == 'detalle') {
                                this.getDataCotizacionId(this.$route.query.cid)
                                this.viewCotizacion = true;
                            } else {
                                this.getDataCotizacionId(this.$route.query.cid)
                                this.viewCotizacion = true;
                            }
                            this.$router.replace({
                                name: 'cotizaciones',
                                force: true
                            });

                        }
                        //console.log(this.$route.query.r,fresult,'recovery')
                    }
                    this.reftable = this.reftable + 1;

                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                emitter.emit("loading", false)
            });
        },

        async AVenta() {
            let dat = {
                id: this.el_cotizacion.id,
                venta: this.formatFullDateTime(new Date())

            }
            if (this.el_cotizacion.id == 0) {
                //this.crear(dat)
            } else {
                this.editar(dat, 0, 1)
            }
        },

        async guardarDescuento() {
            let dat = {
                id: this.el_cotizacion.id,
                descuento: this.el_cotizacion.descuento,
                concepto_descuento_personalizado: this.el_cotizacion.concepto_descuento_personalizado,
                descuento_personalizado: this.el_cotizacion.descuento_personalizado,

            }
            if (this.el_cotizacion.id == 0) {
                //this.crear(dat)
            } else {
                this.Editar_Descuento(dat)
            }
        },
        async guardarLink() {
            let dat = {
                id: this.el_cotizacion.id,
                url_files: this.el_cotizacion.url_files,
               

            }
            if (this.el_cotizacion.id == 0) {
                //this.crear(dat)
            } else {
                this.Editar_Link(dat)
            }
        },
        async Editar_Link(dat) {
            emitter.emit("loading", true)

            await cotizaciones.EDITAR_LINK(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    // this.el_cotizacion.descuento = response;
                    this.reftable = this.reftable + 1;
                    this.editarlink_archivos = false;
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async Editar_Descuento(dat) {
            emitter.emit("loading", true)

            await cotizaciones.EDITAR_DESCUENTO(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    // this.el_cotizacion.descuento = response;
                    this.reftable = this.reftable + 1;
                    this.editarDescuento = false;
                    //this.$router.push('/app/'); 
                } else {

                    emitter.emit("alerta_show", response.error);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },

        async guardar() {
            let dat = {
                id: this.el_cotizacion.id,
                observaciones: this.el_cotizacion.observaciones,
                datos_cotizacion: this.el_cotizacion.datos_cotizacion,

            }
            if (this.el_cotizacion.id == 0) {
                //this.crear(dat)
            } else {
                this.editar(dat, 0, 0)
            }
        },
        async editar(dat, redirectDetalle = 0, redirectabonos = 0) {
            emitter.emit("loading", true)

            await cotizaciones.EDITAR_PARAMS(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.el_cotizacion = response;

                    this.reftable = this.reftable + 1;
                    this.editarobservacion = false;
                    if (redirectDetalle) {
                        this.$router.push('/app/detalle/ventas?cid=' + dat.id);
                    }
                    if (redirectabonos) {
                        this.$router.push('/app/detalle/ventas?cid=' + dat.id + '&tv=pagos');
                    }

                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },

        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await cotizaciones.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData('eliminar');
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
