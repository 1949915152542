import initmixin from "@/Services/initmixin";

export default {
  //mixins: [initmixin],
  // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES
  GETGARANTIA: async function(data) {
    console.log(data, "--sellll--------");
    let url =
      "/garantia/orden/" +
      data ;
     
    return initmixin.formatrespuesta("get", data, url, true);
  },
  GET_COTIZACIONES22: async function(data) {
    console.log(data, "--sellll--------");
    let url =
      "/cotizaciones/page/" +
      data.page +
      "/limit/" +
      data.limit +
      "/search/" +
      data.search;
    return initmixin.formatrespuesta("get", data.data, url, true);
  },
  GET_VENTA_COTIZACION: async function(data) {
    let url = "";

    url =
      "/cotizaciones/" +
      data.ruta +
      "?page=" +
      data.page +
      "&search=" +
      data.search +
      "&tipo=buscador&limit=" +
      data.limit;

    return initmixin.formatrespuesta("get", data, url, true);
  },



  
  GET_PRODUCCION_REVISAR_03: async function(data) {
  
    let url = "/cotizaciones/produccion_revisar";
    url += "?" + new URLSearchParams(data).toString();
    if (data.tipo == "buscador") {
      url += "&limit=" + data.limit;
    }
    return initmixin.formatrespuesta("get", data, url, true);
  },

  GET_PRODUCCION_FORMATOS_TERMINADOS_07: async function(data) {
  
    let url = "/cotizaciones/produccion_terminados";
    url += "?" + new URLSearchParams(data).toString();
    if (data.tipo == "buscador") {
      url += "&limit=" + data.limit;
    }
    return initmixin.formatrespuesta("get", data, url, true);
  },


  GET_PRODUCCION_AVANCE_04: async function(data) {
  
    let url = "/cotizaciones/produccion_avance";
    url += "?" + new URLSearchParams(data).toString();
    if (data.tipo == "buscador") {
      url += "&limit=" + data.limit;
    }
    return initmixin.formatrespuesta("get", data, url, true);
  },
  GET_PRODUCCION_AVANCE_05: async function(data) {
  
    let url = "/cotizaciones/produccion_instalacion";
    url += "?" + new URLSearchParams(data).toString();
    if (data.tipo == "buscador") {
      url += "&limit=" + data.limit;
    }
    return initmixin.formatrespuesta("get", data, url, true);
  },
  GET_PRODUCCION_INSTALADOS_06:async function(data) {
  
    let url = "/cotizaciones/instalados";
    url += "?" + new URLSearchParams(data).toString();
    if (data.tipo == "buscador") {
      url += "&limit=" + data.limit+'&inicio='+data.inicio+'&fin='+data.fin;
    }
    return initmixin.formatrespuesta("get", data, url, true);
  },
  GET: async function(data) {
    /*let url='';
  if(data.hasOwnProperty('fin')){
     url='/cotizaciones/?page='+data.page+'&search='+data.search+'&inicio='+data.inicio+'&fin='+data.fin+'&tipo='+data.tipo
    
  }else{
     url='/cotizaciones/?page='+data.page+'&search='+data.search+'&tipo=buscador&limit='+data.limit
    
  }*/
    let url = "/cotizaciones/";
    url += "?" + new URLSearchParams(data).toString();
    if (data.tipo == "buscador") {
      url += "&limit=" + data.limit;
    }
    return initmixin.formatrespuesta("get", data, url, true);
  },

  VISTA_COTIZACIONES: async function(data) {
    let url = "/cotizaciones/vista/cotizaciones";
    url += "?" + new URLSearchParams(data).toString();
    if (data.tipo == "buscador") {
      url += "&limit=" + data.limit;
    }
    return initmixin.formatrespuesta("get", data, url, true);
  },
  VISTA_VENTAS: async function(data) {
    let url = "/cotizaciones/vista/ventas";
    url += "?" + new URLSearchParams(data).toString();
    if (data.tipo == "buscador") {
      url += "&limit=" + data.limit;
    }
    return initmixin.formatrespuesta("get", data, url, true);
  },

  CREAR: async function(data) {
    let url = "/cotizaciones/";
    return initmixin.formatrespuesta("post", data, url, true);
  },
  SHOW: async function(data) {
    let url = "/cotizaciones/" + data.id;
    return initmixin.formatrespuesta("get", data, url, true);
  },
  SET_FINAL: async function(data) {
    let url = "/cotizaciones/final/" + data.id;
    return initmixin.formatrespuesta("put", data, url, true);
  },
  EDITAR_LINK: async function(data) {
    let url = "/cotizaciones/link/" + data.id;
    return initmixin.formatrespuesta("put", data, url, true);
  },
  
  UPDATE_COMPRAS: async function(data) {
    let url = "/cotizaciones/" + data.id + "/compras/";
    return initmixin.formatrespuesta("put", data, url, true);
  },

  REVISAR_CATEGORIA_PRODUCCION: async function(data) {
    let url =
      "/cotizaciones/" +
      data.idcot +
      "/produccion/revisar/categoria/" +
      data.id;
    return initmixin.formatrespuesta("put", data, url, true);
  },

  EDITAR_DESCUENTO: async function(data) {
    let url = "/cotizaciones/descuento/" + data.id;
    return initmixin.formatrespuesta("put", data, url, true);
  },
  EDITAR: async function(data) {
    let url = "/cotizaciones/" + data.id;
    return initmixin.formatrespuesta("put", data, url, true);
  },
  SEND_WHATSAPPGARANTIA: async function(data) {
    let url = "/cotizaciones/whatsapp/garantia/" + data.id;
    return initmixin.formatrespuesta("get", data, url, true);
  },
  SEND_WHATSAPP: async function(data) {
    let url = "/cotizaciones/whatsapp/" + data.id;
    return initmixin.formatrespuesta("get", data, url, true);
  },
  SEND_EMAIL: async function(data) {
    let url = "/cotizaciones/email/";
    return initmixin.formatrespuesta("post", data, url, true);
  },

  EDITAR_PRODUCCION: async function(data) {
    let url = "/cotizaciones/produccion/" + data.id;
    return initmixin.formatrespuesta("put", data, url, true);
  },
  EDITAR_PARAMS: async function(data) {
    let url = "/cotizaciones/params/" + data.id;
    return initmixin.formatrespuesta("put", data, url, true);
  },
  ELIMINAR: async function(data) {
    let url = "/cotizaciones/" + data.id;
    return initmixin.formatrespuesta("delete", data, url, true);
  },
  CANCELAR_VENTA: async function(data) {
    let url = "/cotizaciones/cancelar/" + data.id;
    return initmixin.formatrespuesta("get", data, url, true);
  },
};
